/* Overall container for the order subscriptions */
.order-subscriptions-container {
  background-color: #faf9ec;
  padding: 40px;
  padding-top: 70px;
  margin: 0 auto;
  height: 100vh;
}

/* Title section */
.order-title-adjust {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.order-title-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-bottom: 0;
  gap: 8px;
  /* background: #FFF200; */
  margin-bottom: 10px;
  text-decoration: underline;
  text-decoration-color: #FFF200;
  text-underline-offset: 8px;
  text-decoration-thickness: 3px;
}

.order-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #1A1A1A;
}

.sub-titl {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1A1A1A;
}

/* Table container */
.order-subscriptions-box {
  background-color: white;
  border-radius: 10px;
  padding: 2%;
  border: 1px solid lightgray;
  
}

/* Adjusting table headers for better alignment and padding */
.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table th {
  padding: 10px;
  /* text-align: left; */
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
  position: sticky;
  top: 0;
}

.order-table td {
 
  font-family: 'Inter', sans-serif;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
}

/* Specific adjustments for each table header */
.order-table th {
  background-color: #f9f9f9;
  font-weight: 600;
  /* text-transform: uppercase; */
}

.order-table th:first-child {
  text-align: left;
  width: 30%; /* Wider for service details */
}

.order-table th:nth-child(2),
.order-table th:nth-child(3),
.order-table th:nth-child(4),
.order-table th:nth-child(5),
.order-table th:nth-child(6) {
 /* text-align: center; Center-align other columns */
 /* width: 12%;  Adjust widths to distribute evenly */
}

.order-table td {
  font-size: 13px;
  color: #555;
  vertical-align: middle; /* Vertically align table cells */
      padding: 5px;
  padding-left: 10px;
  text-align: left;
}

/* Adjust padding for the first column to give space for icons */
.order-service-details {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.order-service-name {
  font-size: 15px;
  font-weight: 500;
}

/* Center-align other columns */
.order-license-count,
.order-expiring-on,
.order-billing-date,
.order-billing-date1 {
  text-align: center; /* Center-align text */
}

/* Add some hover effects for better interaction */
.order-subscription-item:hover {
  /* background-color: #f0f8ff; */
  cursor: pointer;
}

.p-details {
  color: #5B5B5B;
  font-size: 12px;
}

/* General spacing and alignment for icons */
.order-service-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.order-actions {
  justify-content: center;
  padding-right: 35px;
}

.order-service-icon1 {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.order-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter:unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1;
}

.myorders-modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  /* max-width: 600px; */
  width: 90%;
  z-index: 1000;
}

.order-popup-header {
  display: flex;
  justify-content: space-between;

  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.order-service-icon {
  margin-right: 10px; /* Adjust the spacing between the icon and text */
}

.order-popup-header svg {
  margin-right: 5px; /* Adjust the spacing between the arrow and icon */
}

.order-popup-header span:last-child {
  margin-left: auto;
  font-size: 16px;
}


.order-popup-body {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
  margin-top: 40px;
  margin-left: 0px;
}

.order-popup-section {
  flex: auto;
  margin-right: 0px;
  min-width: 90px;
}

.order-popup-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
}

.order-popup-footer {
 
  font-size: 1.2rem;
  display: flex;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
}

.order-popup-footer span:last-child {
  margin-left: auto; 
  font-weight: bold;
  font-size: 16px;
}
.order-popup p {
  margin: 5px 0;
  font-size: 15px;
}
.p-details{
  color: 
  #5B5B5B;
  font-size: 12px;
}
/* Add these styles to the container around the table */
.order-table-wrapper {
  max-height: 60vh; /* Set a specific height for scrolling */
  overflow-y: scroll; /* Enable vertical scrolling */
  margin-top: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border:1px solid #d3d3d3;

}
/* Custom scrollbar styling */
.order-table-wrapper::-webkit-scrollbar {
  width: 4px; /* Set the scrollbar width */
}

.order-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #FFF200; /* Set the thumb color */
  border-radius: 10px; /* Set the thumb border radius */
  min-height: 80px;
}


.order-table-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the track background color */
  border-radius: 10px; /* Set the track border radius */
}

.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table thead {
  background-color: #f1f1f1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  height:45px;
}

.order-table tbody {
  background-color: white;
}
.order-subscriptions-box{
  background-color: white;
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  padding: 2%;
 
}

.no-subscriptions {
  text-align: center;
  padding: 40px;
  box-sizing: content-box;
  border: 1px solid #e0e0e0;
  color:#e25555;
}

@media (max-width:768px){
  .item-name-model{
    font-size: 10px !important;
  }
  .order-popup p {
    font-size: 12px;
  }
  .myorders-modal-content{
    padding: 40px 10px;
    width: 98%;
  }
}