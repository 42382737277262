/* .container-fluid {
    height: 100vh;
} */

.reset-pwd-left-content {
    background: #FAF9EB;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#brio-login {
    margin-top: 4%;
}

.content {
    width: 80%;
    margin-top: 60px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #939598;
}

.contact-container {
    margin-bottom: 2%;
    display: flex;
}

.cnt-sprt {
    cursor: pointer;
}

.cnt-sprt-text {
    font-size: 12px;
    cursor: pointer;
    color: black;
    margin-left: 4px;
    margin-top: 4px;
}

.forgot-pwd-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.reset-pwd1 {
    margin-top: 10px;
}

.reset-pwd-text1 {
    font-size: 24px;
    font-weight: 550;
    font-family: Roboto;
}

.success-message1 {
    color: black;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    background-color: #8BF2A8;
}
 /* .container-fluid {
    height: 100vh;
} */

.forgot-pwd-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.email-section1 {
    margin-top: 20px;
    width: 100%;
}

.form-lable {
    color: #9A9A9A;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

.input-box {
    width: 100%;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
  
}

.input-box input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #1A1A1A;
    box-sizing: border-box;
    padding-left: 12px;
}

.login-bnf {
    width: 100%;
    height: 48px;
    background-color: #FEF200;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.login-bn-textf {
    font-weight: 700;
    font-size: 16px;
    line-height: 39px;
    color: #1A1A1A;
    background: transparent;
    border: none;
    cursor: pointer;
}



#brio-logof {
    margin-top: -90px;
    margin-bottom: 130px;
}

.contact-support .icon {
    width: 37px;
    height: 37px;
}

.text2 {
    margin-right: 20px;
    box-sizing: border-box;

}

.cnt-sprt2 {

    margin-top: 150px;
    cursor: pointer;
}

.cnt-sprt2-text {

    margin-left: 30px;
    margin-top: -50px;

    font-size: 10px;
    cursor: pointer;
    color: black;
    background: none,

}

.bk-sign-bn1 {
    margin-top: -55px;
    cursor: pointer;
    margin-right: 20px;
}

.reset-pwd1 {
    margin-top: 10px;
}

.reset-pwd-text1 {
    font-size: 24px;
    font-weight: 550;
    font-family: Roboto;
}

.success-message1 {
    color: black;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    background-color: #8BF2A8;
}

.back-to-sign-container{
    margin-top:12px;
}

.RSP-container {
    display: flex;
    height: 100vh;
  }
  
  .RSP-leftSection {
    flex: 1;
    background-color: #FBF9EF;
    padding: 30px 0px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }
  
  .RSP-logo {
    width: 112px;
  }
  
  .RSP-slogan {
    font-size: 48px;
    color: #7E7E7E;
    line-height: 1.4;
    font-weight: 700;
  }
  
  .RSP-boldText {
    font-weight: bold;
    color: #000;
  }
  
  .RSP-support {
    display: flex;
    align-items: center;
  }
  
  .RSP-supportIcon {
    margin-right: 8px;
    width: 20px;
  }
  
  .RSP-supportText  {
    color: #000;
    font-size: 12px;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid lightgrey;
    border-left: none;
    border-image: initial;
    text-decoration: none;
    background: none;
    font-weight: 600;
    cursor: pointer;
}
  
  .RSP-rightSection {
    flex: 0.4;
    background-color: #fff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .RSP-title {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #000;
  }
  
  .RSP-form {
    width: 100%;
    max-width: 400px;
  }
  
  .RSP-label {
    font-size: 14px;
    color: #7E7E7E;
    margin-bottom: 8px;
  }
  
  .RSP-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    font-size: 14px;
  }
  
  .RSP-submitButton {
    width: 100%;
    padding: 12px;
    background-color: #FFF200;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .RSP-backToSignIn {
    margin-top: 20px;
    font-size: 14px;
    color: #000;
    border-bottom: 2px solid #000;
    text-decoration: none;
  }

  .RSP-errorMessage {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    border: 1px solid #f5c6cb;
  }
  

@media only screen and (max-width: 768px) {
    .reset-pwd-left-content {
        display: none;
    }
    .RSP-leftSection{
        display: none;
    }
    .forgot-pwd-content {
        position: fixed;
        width: 100%;
    }
    .RSP-rightSection{
        position: fixed;
        width: 100%;
        height: 100%;
    }
}