body{
    background: #F9F9F9;
}

.App {
    font-family: Arial, sans-serif;
    width: 100%;
    height: 100%;
   /* display: flex;*/
    flex-direction: column;
    background: #F9F9F9;
}

.LPApp {
    font-family: Arial, sans-serif;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.banner{
    width: 100%;
    height: 800px;
    padding: 130px 20px 50px !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.LPMainContainer {
    background: #F9F9F9;
    margin-top: 6%;
}

.DashMainContainer {
    background: #F9F9F9;
}

.customerName {
    left: 11%;
    /* position: absolute; */
}

.LPHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FDFDFD;
    border-bottom: 1px solid #DADADA;
     height: 80px;
}
.LPnavbar{
position: fixed;
  top: 0;   
  left: 0; 
  width: 100%;
  background-color: white; 
  z-index: 1000; 
  height: 80px;
}

.LPLogo {
    position: relative;
    width: auto;
    height: 48px;
    left: 20px;
    border-radius: 2px;
}

.LPSearchContainer {
    /* position: absolute; */
    width: 60%;
    height: 48px;
    /* right: 23%; */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
}

.LPSearchInput {
    flex: 1;
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
    outline: none;
}

.LPIconSearch {

    /* width: 24px;
    height: 24px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.LPIconSearch img {
    width: 100%;
    height: 100%;
}

.LPHeaderButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
}
.LPHeaderButtons.wide{
    width: 50%;
}
.LPcountry-selector {
    /* position: fixed; */
    top:16px;
    right:22%;
    /* margin-right: 80px; */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    cursor: pointer;

  }
  .LPLabel-selector{
    /* position: fixed; */
    top:16px;
    right:11%;
    /* margin-right: 80px; */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    cursor: pointer;
  }

  .NBdropdown-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    gap: 6px;
    background: #FDFDFD;
    /* border: 1px solid #DADADA; */
    border-radius: 8px;
  }
  .NBLabel-header {
    box-sizing: border-box;
    display: flex;
    /* width: 150px; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px;
    background: #FDFDFD;
    /* border: 1px solid #DADADA; */
    border-radius: 8px;
  }

  .LPdropdown-body{
    margin-top: 16px;
    border-radius: 8px;
    position: absolute;
    z-index: 12000;
    background: #FDFDFD;
    min-width: 110px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .LPcountry-icon {
    width: 30px;
    height: 30px;
    background-size: 24px 24px !important;
    background-position: center;
    background-repeat: no-repeat;
}
  .LPlabel-icon{
    width: 30px;
    height: 30px;
    background-size:20px 20px !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .LPcountry-name {
    /* Saudi Arabia */

    /* width: 0px; */
    height: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    color: #1A1A1A;
    overflow: visible;
  }

  .LParrow-icon {
    width: 24px;
    height: 24px;
    background:  url('../Assets/dropdownicon.svg') no-repeat center center;
    background-size: contain;
    /*transform: rotate(-90deg);*/
    transition: transform 0.3s;
  }

    .LParrow-icon.open {
        transform: rotate(180deg);
    }


  .LPdropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    border-top: 1px solid #DADADA;
    cursor: pointer;
  }

  .LPcheck-icon {
    width: 24px;
    height: 24px;
    background: #8BF2A8;
    /*border-radius: 12px;*/
  }
  .LPcheck-icon1 {
    width: 24px;
    height: 24px;

  }

.LPFeaturedServices {
    text-align: center;
    display: flex;
    flex-direction: column;
    width:100%;
    /* padding-top:3%; */
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.LPServices {
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    align-items: center;
}

.bannerContent {
    max-width: 600px;
    margin: 0 auto 0 55px;
}

.LPServicesHeading {
    display: flex;
    justify-content: center ;
    flex-direction: column;
    align-items: center;
    /* padding-top: 2%; */
    /* padding-bottom:1%; */
    gap: 4px;
    font-family: 'Inter', sans-serif;
    /* width: 482px; */
    height: auto;
}
.LPServicesHeading span{
    /* background:#FFF200; */
    padding: 8px;
    font-size: 28px;
    font-weight:650;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.LPCardContainer {
    display: flex;
    justify-content: space-around;
    gap: 0%;
    width:100%;
    padding: 1%;
}

.NBPrimaryButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px;
    gap: 8px;
    background:#FFF200;
    border:none;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
    cursor: pointer;
    /* position: fixed; */
    top:16px;
    right:2%;
    /* border: 1px solid #FFF200; */
}

.LPIconContainer {
    width: 24px;
    height: 24px;
}

.LPIcon {
    width: 100%;
    height: 100%;
}



.LPframe51 {
    text-decoration: none; /* Ensure no underline for the link */
    display: flex;
    align-items: center;
    gap: 8px;
    color: inherit; /* To maintain the original color, if not, you can set a custom color */
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    padding: 12px 16px;
}

.LPframe51 div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.LPframe51 span {
    text-decoration: none; /* Additional check */
}


/*CARD CSS ********************************/

.card {
    display:flex;
    width:30%;
    flex-direction: column;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    margin:10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card-header-1 {

    display: flex;
    height:15%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #F1F1F1;
}
.serviceTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
    width: 65%;
    margin: 35px;
    flex: none;
    order: 0;
    height: 45%;
    flex-grow: 0;
    min-width: 250px;
}
.serviceTitlebelow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 27px;
    margin-bottom: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
}



    .serviceTitlebelow .part1 {
        /* width: 70px;
        height: 17px; */
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        color: #5B5B5B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .serviceTitlebelow .part2 {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1A1A1A;
        flex: none;
        order: 1;
        flex-grow: 0;
    }


.features {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 2px;
}

.feature {
    /* width: 16%; */
    /* padding: 4%; */
    text-align: center;
}
.descriptionHeading {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    margin-left: 15px;
    font-weight: 550;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
    text-align: left;
}

.Descriptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    margin-left:10px;
    top: 432px; 
}

.description {
    display: flex;
    font-family: 'Inter', sans-serif;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 10px;
}



.description p {
    /* Lorem ipsum dolor sit amet consectetur. Non et phasellus quis habitant odio tincidunt at tincidunt neque. */
    display: flex;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 17px; */
    color: #5B5B5B;
    text-align: left;
}

.erp-price{
    display: flex;
    gap: 10px;
}

.frame-24 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 95%;
    margin: 6px auto;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 14px 14px;
    gap: 2%;
}

.frame-26 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 12px;
    gap: 5px;
    background: #FFF200;
    border-radius: 6px;
    cursor: pointer;
}
.frame-65 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.frame-getstarted {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 12px;
    gap: 5px;
    background: #FFF200;
    border-radius: 6px;
    cursor: pointer;
    width: 44%;
    margin-top: 22px;
    margin-left: 15px;
}

.buy-now {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 20px;
    color: #1A1A1A;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.starting-at {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
}

.price {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    margin: 0;
}

.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
}

.error-msg-notification-container {
    position: fixed;
    top: 98px;
    right: 20px;
    z-index: 9999;
}

.notification {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 8px 0;
    font-family: Arial, sans-serif;
    position: relative;
    max-width: 400px; /* Adjust the width as needed */
    transition: opacity 0.5s ease-in-out; /* Transition for fading */
}

/* Fade-out animation */
.notification.fade-out {
    opacity: 0;
}

/* Error notification styles */
.notification.error {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Border to match the background */
}

.notification-icon.error-icon {
    color: #f5c6cb; /* Icon color for error */
}

/* Success notification styles */
.notification.success {
    background-color: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb; /* Border to match the background */
}

.notification-icon.success-icon {
    color: #c3e6cb; /* Icon color for success */
}

/* Shared styles for close button */
.notification-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.notification-close svg {
    margin-bottom: 5px;
}
/* cart icon css */
/* Cart Container */
.LPcart {
    position: fixed;
    top:28px;
    right:12%;
    display: flex;
    align-items: center;
    
    cursor: pointer; /* Makes the cart clickable */
}
.LPorders{
    position: fixed;
    top:26px;
    right:15%;
    display: flex;
    align-items: center;
    
    cursor: pointer; /* Makes the cart clickable */
}

/* Cart Icon */
.LPcart-icon {
    width: 24px; /* Adjust size as needed */
    height: 24px;
}
.LPorders-icon {
    width: 30px; /* Adjust size as needed */
    height: 30px;
}

/* Cart Count */
.LPcart-count {
    background-color: red; /* Background color for the count badge */
    color: white; /* Text color */
    font-size: 12px; /* Adjust text size */
    border-radius: 50%; /* Makes the badge circular */
    padding: 2px 6px; /* Adjust padding for size */
    position: absolute;
    top: -10px; /* Positioning above the cart icon */
    right: -10px; /* Positioning to the right of the cart icon */
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px; /* Ensures badge has a minimum width */
    height: 18px; /* Ensures badge is a perfect circle */
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 300px;
  }
  
  .dropdown-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .dropdown-display:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-items {
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    max-height: 250px;
    overflow-y: auto;
    z-index: 10;
    font-size: 11px;
  }
  
  .dropdown-items input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ccc;
  }
  
  .dropdown-items div {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-items div:hover {
    background-color: #f0f0f0;
  }

.user-modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.sync-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.user-input{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.sync-submit{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.Sync-button {
    background-color: #FFF200;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: inline-block;
  }

.footer-top {
    background-color: #F1F1F1;
    display: flex;
    justify-content: center;
    width: 100%;
}

.footer-cities {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    justify-content: center; /* Center content */
    gap: 10px; /* Add spacing between items */
}

.city-icon {
    max-width: 115px;
    height: auto;
    border-radius: 8px;
}

.footer-bottom {
    background-color:#1A1A1A;
    display: flex;
    justify-content: space-between;
}

.socials {
    display:flex;
    gap:8px;
    padding:10px 0;
}

.socials-link {
    display: flex;
    gap: 12%;
}

.contacts {
    color: whitesmoke;
}

.contact-link {
    padding:15px;
    display: flex;
    flex-direction: column;
}

.contact-link a {
    text-decoration: none;
    color: white;
}

.contact-link a:hover {
    color:#9A9A9A;
}

.link{
    font-size:13px;
}

.section {
    display: flex;
}

.second-header {
    border-bottom: 3px solid rgb(255, 242, 0);
    margin: 15px;
}

.city-icon:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.faq-section {
    margin: 80px 0 0; /* 80px gap from top and bottom */
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-heading {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 4px solid #FFF200;
}

.faq-box {
    border-radius: 5px;
    padding: 15px;
    width: 800px;
  }
  
  .faq-item {
    padding: 10px 0;
    cursor: pointer;
  }
  
  .faq-item:last-child {
    border-bottom: none;
  }
  
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
      font-size: 16px;
      margin-top: 10px;
      color: #555;
      display: none;
    }
  .highlighted {
    font-weight: bolder;
    font-size: 17px;
    color: #2c2c2c;
  }
  
  .faq-icon {
    transition: transform 0.2s ease;
  }
  
  .faq-item.active .faq-icon {
    transform: rotate(180deg);
  }
  
  .faq-item.active .faq-answer {
    display: block;
  }

@media (max-width: 800px) {
    .faq-box{
        width: 90%;
    }
}

@media (max-width:650px) and (min-width: 450px) {
    .section {
        flex-direction: column;
    }
}
@media (max-width:450px) {
    .footer-bottom {
        flex-direction: column;
    }
    .footer-logo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .second-header {
        margin: 5px  !important;
    }
    .contact-link {
        padding: 5px;
    }
    .link {
        font-size: 10px;
    }
    .LPHeaderButtons{
        width:30%
    }
    .banner{
        height: 950px;
    }
}
@media (max-width: 400px){
    .card{
        width: 90% !important;
    }
    .LPFeaturedServices{
        padding-top: 7% !important;
        /* height: 100vh; */
    }
    .LPMainContainer{
        height: 140vh !important;
        margin-top: 20% !important;
    }
    .LPLabel-selector{
        right: 14% !important;
    }
    .LPdropdown-item{
        gap:0px;
    }
    .LPdropdown-body a{
        padding:5px 0px !important;
    }
    .NBLabel-header{
        gap:0px;
        padding:8px 0px;
    }
    .LPcountry-name{
        width: 63px !important;
        font-size: 13px !important;
    }
    .mySub{
        overflow: scroll;
    }
    .banner-button{
        flex-direction: column;
    }
    .frame-getstarted{
        width: 70%;
    }
}

@media (min-width: 400px) and (max-width: 915px) {
    .card{
        width: 95% !important;
    }
    .LPFeaturedServices{
        padding-top: 5% !important;
        /* height: 100vh; */
    }
    .LPMainContainer{
        height: 110vh !important;
        margin-top: 15% !important;
    }
    .LPLabel-selector{
        right: 14% !important;
    }
    .LPdropdown-item{
        gap:0px;
    }
    .LPdropdown-body a{
        padding:5px 0px !important;
    }
    .mySub{
        overflow: scroll;
    }
    .NBdropdown-header{
        gap:0px
    }
    .LPcountry-selector{
        right: 27% !important;
    }
    .banner{
        height: 870px;
    }
}
@media (max-width:1180px) {
    .NBPrimaryButton{
        gap: 4px;
        font-size: 12px;
    }
    .LPcountry-name{
        font-size: 13px !important;
    }
    .NBLabel-header{
        gap:0px;
        padding:8px 0px;
    }
}

@media (max-width: 1080px) {
    .customerName{
        left: 13%;
    }
}

@media (max-width: 915px) {
    .LPHeaderButtons.wide{
        width:63%
    }
}