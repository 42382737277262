.carousel-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px; /* Adjust based on your requirement */
    background-size: cover;
    background-position: center;
    background-image: url("../Assets/banerBackground.png"); /* Background image for each slide */
}

.carousel-slide-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 800px; /* Adjust based on your requirement */
    background-size: cover;
    background-position: center;
    background-image: url("../Assets/surfaceBanner.png"); /* Background image for each slide */
}

.bannerContent {
    color: white;
    text-align: left;
    max-width: 600px;
    margin: 80px auto 10px 35px;
    padding-top: 5%;
    margin-left: 5%;
}

.banner2Content {
    color: black;
    text-align: left;
    max-width: 600px;
    margin: 80px auto 10px 35px;
    padding-top: 5%;
    margin-left: 5%;
}

.banner-title {
    /* font-size: 2.5rem; */
    font-weight: bold;
    margin-bottom: 10px;
}

.banner-title2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 60px;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
}

.banner-subtitle {
    font-weight: bold;
    margin: 10px 0 20px;
    line-height: 1.5;
}

.banner-divider {
    width: 20%;
    height: 4px;
    background-color: #FFF200;
    margin: 0 auto 20px 0;
}

.banner-highlight {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
}

.price-label {
    font-size: 14px;
  }
  
  .old-price {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .line-through {
    text-decoration: line-through;
    font-size: 21px;
  }
  
  .new-price {
    margin-bottom: 0;
  }
  
  .banner-title {
    font-size: 38px;
    font-weight: 600;
  }
  
  .gst {
    font-size: 20px;
    font-weight: 600;
  }
  .price-row {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between elements */
    margin-top: 5px;
  }  

.slick-dots {
    bottom: 15px;
}

.slick-dots li button:before {
    color: rgba(71, 71, 71, 0.281);
    font-size: 12px;
}

.slick-dots li.slick-active button:before {
    color: #FFF200;
}

.slick-arrow {
    z-index: 1000;
}

@media (min-width: 769px){
    .laptop-image {
        display: none;
    }
}

@media (max-width: 768px) {
    .carousel-slide-2 {
        background-image: url("../Assets/backgroundImage2.png");
    }
}
@media (max-width:400px){
    .banner-title {
        font-size: 2.3rem;
    }
    .banner-title2 {
        font-size: 2.3rem;
    }
    .frame-getstarted{
        margin-left: 0;
        width: 100%;
    }
    
}