.container-fluid {
    height: 100vh;
}

.sign-up-left-content {
    background: #FAF9EB;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#brio-login {
    margin-top: 4%;
}

.content {
    width: 80%;
    margin-top: 60px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #939598;
}

.contact-container {
    margin-bottom: 2%;
    display: flex;
}


.cnt-sprt {
    cursor: pointer;
}

.cnt-sprt-text {
    font-size: 12px;
    cursor: pointer;
    color: black;
    margin-left: 4px;
    margin-top: 4px;
}

.sign-up-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.frame1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.signup-text {
    width: 135px;
    height: 44px;
    font-weight: 700;
    font-size: 22px;
    line-height: 39px;
    text-align: center;
    color: #1A1A1A;
    /* background: #FEF200; */
    border-radius: 5px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.form-lable {
    color: #9A9A9A;
    display: block;
    margin-bottom: 3px;
    text-align: left;
}


.input-box {
    width: 100%;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
}

.input-box input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #1A1A1A;
    box-sizing: border-box;
    padding-left: 12px;
}


.signup-bn {
    width: 100%;
    height: 48px;
    background-color: #FEF200;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 28px;
}

.signup-bn-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    color: #1A1A1A;
    background: transparent;
    border: none;
    cursor: pointer;
}

.back-to-login-container{
    margin-top:12px;
}

@media only screen and (max-width: 768px) {
    .sign-up-left-content {
        display: none;
    }
    .sign-up-content {
        position: fixed;
        width: 100%;
    }
}

.success-message {
    color: #18c421;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: left; 
}
