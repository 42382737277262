.item-card {
    display:flex;
    width:28%;
    flex-direction: column;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
}
.item-card-header-1 {

    display: flex;
    height:12%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #1A1A1A;
}

.item-card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top:40px;
    margin-left:25px;
}

.item-serviceTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 548;
    font-size: 16px;
    line-height: 24px;
    color: #FFF200;
    margin-top: 15px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.item-serviceTitlebelow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    margin-bottom: 15px;
    flex: none;
    order: 1;
    flex-grow: 0;
}



    .item-serviceTitlebelow .part1 {
        /* width: 70px;
        height: 17px; */
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #FDFDFD;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .item-serviceTitlebelow .part2 {
        /* $6.00 Month */

        /* width: 115px;
        height: 22px; */
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #FFF200;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

.item-features {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.item-feature  {
    width: 25%; /* Each row will have 4 features */
    padding: 5px 0;
    text-align: center;
}

.item-feature img {
    width: 60%;
}
.item-feature-default img {
    width: 100%;
}

.item-feature-default {
    width: 100%; /* Each row will have 4 features */
    padding: 5px 0;
    text-align: center;
}

.item-descriptionHeading {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    margin-top: 10px;
    font-weight: 550;
    font-size: 14px;
    line-height: 18px;
    color: #1A1A1A;
    padding-left: 10px;
}



.item-Descriptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    margin-left:10px;
    top: 432px; 
}

.item-Descriptions span{
    font-size: 12px;
}



.item-description {
    display: flex;
    font-family: 'Inter', sans-serif;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}



.item-description p {
    /* Lorem ipsum dolor sit amet consectetur. Non et phasellus quis habitant odio tincidunt at tincidunt neque. */
    display: flex;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 17px; */
    color: #5B5B5B;
    text-align: left;
}
.item-frame-24 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width:95%;
    margin: 0 auto;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 14px 20px;
}

.item-frame-26 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 8px;
    background: #FFF200;
    border-radius: 8px;
    cursor: pointer;
}
.item-frame-65 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.item-buy-now {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.item-starting-at {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
}

.item-price {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}