/* NAVBAR CSS */
/* .LPnavbar{ */
    /* position: fixed;
      top: 0;   
      left: 0; 
      width: 100%;
      background-color: white;  */
      /* z-index: 1000;  */
    /* } */
    .LPframe51 {
        text-decoration: none; /* Ensure no underline for the link */
        display: flex;
        align-items: center;
        gap: 8px;
        color: inherit; /* To maintain the original color, if not, you can set a custom color */
        border: 1px solid #9A9A9A;
        border-radius: 8px;
        padding: 12px 16px;
    }
    
/*MY SUBSCRIPTIONS CSS ************************************************************/

.LPServicesHeading {
    display: flex;
    justify-content: center ;
    flex-direction: column;
    align-items: center;
    /* padding-top: 5%; */
    /* padding-bottom:1%; */
    gap: 4px;
    font-family: 'Inter', sans-serif;
    /* width: 482px; */
    height: auto;
}
.LPServicesHeading span{
    /* background:#FFF200; */
    padding: 8px;
    font-size: 28px;
    font-weight:650;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}




/* Container */

.ms-title-box {
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 50px;
    padding: 8px;
    gap: 8px;
    /* background: #FFF200; */
    margin-bottom:20px;
    border-radius: 6px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.ms-title-adjust{
    display:flex;
    justify-content:center;
}
.ms-subscriptions-container {
    background-color: #F9F9F9;
    padding: 40px;
    margin: 0 auto;
}

/* Title */
.ms-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    padding-top: 6px;
    line-height: 29px;
    text-align: center;
    color: #1A1A1A;
    flex: none;
    order: 0;
    flex-grow: 0;
}
/* Subscriptions Box */
.ms-subscriptions-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2%;
    width: 100%;
}

/* Section Header */
.ms-section {
    margin-bottom: 30px;
}

.ms-section-title {
    font-size: 18px;
    margin-bottom: 15px;
    font-family: 'Inter', sans-serif;
}

/* Headers */
.ms-header {
    display: flex;
    background: #F1F1F1;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #DADADA;
    border-radius: 8px 8px 0 0;
    padding: 10px;
   /* margin-bottom: 10px;*/
    font-weight: bold;
}

.ms-header-item {
    flex: 0.8;
    text-align: center;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}
.ms-header-item-service {
    flex: 1.5;
    text-align: center;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}

/* Subscription Item */
.ms-subscription-item {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    padding: 15px;
    border: 1px solid #e0e0e0;
}

   /* .ms-subscription-item.expired {
        background-color: #f7f7f7;
    }
*/
/* Service Details */
.ms-service-details {
    display: flex;
    align-items: center;
    flex: 1.5;
}

.ms-service-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.ms-service-name {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}

.ms-service-plan {
    font-size: 14px;
    color: #888;
    font-family: 'Inter', sans-serif;
}

.ms-license-count, .ms-expiring-on, .ms-billing-date {
    flex: 0.8;
    text-align: center;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.ms-actions {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    flex: 0.8;
    gap:13%;
}

.ms-action-button {
    background-color: #f9d702;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
}

/* Explore Services */
.ms-explore-services {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px;
}

@media (max-width: 768px) {
    .ms-explore-services   {
        flex-direction: column;
    }
  }
.ms-explore-services p{
    font-weight: 500;
    font-size:20px;
    margin-top:2%;
    color:#1A1A1A;
}

.ms-explore-button {
    background-color: #FFF200;
    color:#1A1A1A;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    justify-content: center;
    margin-top: 8%;
}

@media (max-width: 768px) {
    .ms-explore-button   {
        margin-top: 1%;
    }
  }

/* Frame 1000003257 */
.ms-frame-1000003257 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    /* width: 1240px;
    height: 240px; */
}



.ms-service-details,
.ms-purchased-licenses,
.ms-next-billing-date,
.ms-actions {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}



/* Content */
.ms-frame-1000003254 {
    /* width: 1240px;
    height: 96px; */
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #DADADA;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
}

/* Logos and vectors */
.ms-frame-1000003256 {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 16px;
    /* width: 339px;
    height: 80px; */
    position: relative;
}

.ms-frame-53 {
    /* width: 80px;
    height: 80px; */
    background: #F9F9F9;
    border-radius: 8px;
    position: relative;
}

.ms-logos-microsoft-icon {
    /* width: 48px;
    height: 48px; */
    position: absolute;
    left: 16px;
    top: 16px;
}

.ms-vector-orange, .ms-vector-green, .ms-vector-blue, .ms-vector-yellow {
    position: absolute;
    /* width: 12px;
    height: 12px; */
}

.ms-vector-orange {
    background: #F1511B;
}

.ms-vector-green {
    background: #80CC28;
}

.ms-vector-blue {
    background: #00ADEF;
}

.ms-vector-yellow {
    background: #FBBC09;
}

/* Text */
.ms-service-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}

.ms-plan-details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5B5B5B;
}

/* Icons */
.ms-action-add {
    width: 24px;
    height: 24px;
    background: #FFF200;
    border-radius: 12px;
}

.ms-action-refresh {
    width: 24px;
    height: 24px;
    background: #9A9A9A;
    border-radius: 12px;
}

.ms-status {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
}

.ms-x01, .ms-x02 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.spinner {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(255, 242, 0);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}


.no-subscriptions {
    text-align: center;
    padding: 40px;
    box-sizing: content-box;
    border: 1px solid #e0e0e0;
    color:#e25555;
}


@media (max-width:768px){
    .ms-section-title{
        margin-top: 10px !important;
        font-size: 16px;
    }
    .TUP-button{
        font-size: 14px;
    }
    .ms-subscriptions-container{
        margin-top: 10%;
        padding: 40px 5px;
    }
    .cart-subscriptions-container{
        padding: 40px 5px !important;
    }
    .order-subscriptions-container{
        padding: 70px 5px 0px !important;
    }
}