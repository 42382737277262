.main-cntnt{
    height: auto;
    width: 100%;
    /* top: 100px;
    left: 320px; */
    background-color: #FAF9EB;
    align-items: center;
}


.frame12 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* gap: 16px; */
    /* width: 576px;
    height: 90px; */
  }
  
 
  
  .microsoftSignIn {
    width: 273px;
    height: 39px;
    background: #FFF200;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  
    color: #1A1A1A;
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .signInText {
    /* width: 372px;
    height: 19px;
   */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 19px; */
  
    color: #1A1A1A;
    /* margin-bottom: 20px;
    margin-right: 100px; */
    flex: none;
    order: 1;
    flex-grow: 0;
  }



  /* fields */
.fields {
    box-sizing: border-box;
    /* position: absolute; */
    /* width: 760px;
    height: 642px; */
    /* left: 410px;
    top: 238px; */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    margin-top:30px;
    width:85%;
    margin-left:5%;
    align-items: center;
}

/* Frame 48 */
.frame48 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
    position: absolute;
    /* width: 832px;
    height: 169px; */
    /* left: 44px;
    top: 40px; */
}

/* Question */
.question {
    width: 541px;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
}

/* Frame 34 */
.frame34 {
    display: flex;
   
  
}
.noAccount{
    display: flex;
}
.yesAccount{
    display: flex;
}
.demo{
    margin-top: 30px;
    display: flex;
    gap: 10px;
    padding-left: 80px;
    padding-right: 80px;
    align-items: center;
    justify-content: center;
}
.iconnnnnnn{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
}
.sanjana{
    margin-top: 20px;
    padding: 10px;
    margin-left: 30px;
    color: gray;
    margin-bottom: -20px;

}
.demo1{
    border: 1px solid #9A9A9A;
   padding: 10px 30px 10px 30px;
    border-radius: 5px;
    display: flex;
    
}
/* Frame 25 */


/* Frame 46 */
.frame25 .icon {
    width: 32px;
    height: 32px;
    background: #FFF200;
    border-radius: 16px;
    position: relative;
}

/* Icon styles */
/* .mdi-account-add, .microsoft-365 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 4px;
} */

.microsoft-365 {
    background: radial-gradient(151.67% 90.24% at 60.98% 20.59%, #AE7FE2 6.4%, #0078D4 100%);
}

/* Text */
/* .text {
    width: 200px;
    height: 80px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
} */

/* Frame 3 */
.frame3 {
    box-sizing: border-box;
   
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    
}

/* Icon for Microsoft 365 */
.frame3 .icon {
    width: 32px;
    height: 32px;
}
.input-flds{
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    /* height: 77px;
    width: 700px; */
  
    margin-left: 30px;
    gap: 5px;
}
.frame15{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
    /* width: 400px;
    height: 77px */
}
.frame27 {
    display: flex;
    justify-content: start;
    flex-direction: column;
    /* align-items: center; */
    padding: 0;
    gap: 12px;
    /* width: 400px; */
    /* height: 57px; */
}
.usernameField{
    width: 500px;
    height: 58px;
}
.domainNameField{
    width: 310px;
    height: 48px;
    display: flex;
    align-items: center;
}
.usernameField input {
    border: 1px solid lightgrey;
    width: 60%;
    height: 100%;
    padding: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
    border-radius: 5px;
}
.domainNameField input {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px 0 0 4px;
    flex: 1;
    border: 1px solid lightgrey;
    width: 60%;
    height: 100%;
    padding: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
    border-radius: 5px;
}
/* Username Label and Field */
.usernameLabel {
  
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
}
.domainNameLabel{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 17px;
    font-size: 14px;

}
.domainNameField .domain {
    background-color: #f0f0f0;
    border: none;
    /* padding: 5px; */
    border-radius:  4px;
    margin-right: 30px;
    position: absolute;
    right: -10px;
    white-space: nowrap;
    
}
/* Vector icons */
.vector {
    position: absolute;
    width: 24px;
    height: 24px;
}

/* removed part */

/* Dropdown */
.ph-caret-up-down-fill {
    position: absolute;
    width: 140px;
    height: 28px;
    right: 12px;
    top: calc(50% - 28px/2);
    background: #E0E0E0;
    border-radius: 4px;
}

/* Domain Name */
.onmicrosoft-com {
    position: absolute;
    width: 116px;
    height: 17px;
    left: 12px;
    top: 6px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1A1A1A;
}

/* Frame 49 */
.frame49 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
 
 
    width: 600;
    height: 113px;
    
}

/* How will you sign in? */
.signInMethod {
    margin-top: 20px;
    margin-left: 30px;
    width: 832px;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
}

/* Additional Information */
.additionalInfo {
    margin-left: 10px;
    
   padding: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
}

/* Frame 26 */
.frame26 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    gap: 8px;
    margin-top: 50px;
    margin-right: 20px;
    width: 100px;
    height: 40px;
   
    background: #FFF200;
    border-radius: 8px;
}
.nikhil{
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;

}
/* Next Button */
.nextButton {
    width: 41px;
    height: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
   
    color: #1A1A1A;
}

/* Arrow Up */
.arrowUp {
    width: 24px;
    height: 24px;
    border: 1.5px solid #1A1A1A;
    transform: rotate(90deg);
}

/* Thank You Message */
.thankYouMessage {
    margin-left: 150px;
    margin-top: -40px;
    border-radius: 4px;
    padding: 5px;
   
    width: 400px;
    height: 64px;
    background: #8BF2A8;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    
    color: black;
}
