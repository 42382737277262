.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-family: Arial, sans-serif;
}

.row {
    display: flex;
    width: 100%;
}

.col-4 {
    width: 30%;
    padding: 2px;
    box-sizing: border-box;
}

.form-heading {
    /* Frame 12 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:20px;
    /*padding: 40px;*/
   /* gap: 16px;*/
    
    width: auto;
    height: 70px;
   
}

    .form-heading h2 {
        /* Frame 27 */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
       /* padding: 8px;*/
        /*gap: 8px;*/
        width: 333px;
        height: 35px;
        background: #FFF200;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .form-heading p{
        margin-top:-10px;
    }


.steps {
    display: flex;
    flex-direction: column;
   /* border-right: 1px solid #ddd;*/
    padding-right: 20px;
}

.step {
    display: flex;
    align-items: center;
    /*margin-bottom: 20px;*/
}

    .step span {
        display: inline-block;
        width: 24px;
        height: 24px;
       /* background-color: #ddd;*/
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
    }

    .step.active span {
        background-color: #ff0;
    }


.contact-us-button {
    background-color: #ff0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
}

h2 {
    margin-top: 0;
}

.form-group {
    margin-bottom: 20px;
}

.radio-group {
    display: flex;
    flex-direction: column;
}

    .radio-group input {
        margin-right: 10px;
    }

.order-summary {
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.payment-due {
    font-weight: bold;
}

/*.next-button {
    background-color: #ff0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
}
*/


.fields {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    align-items: flex-start;
    width: 800px;
    height: 602px;
    margin: 0 auto;
    padding-left: 40px;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: 40px;
}

.frame21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 400px;
    height: auto;
    margin-top: 40px;
    box-sizing: border-box;
}

.frame13, .frame14, .frame15, .frame19, .frame17, .frame18, .frame22, .frame23, .frame24, .frame25, .frame26, .frame27 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.frame13, .frame14, .frame15 {
    width: 100%;
}

    .frame13 label, .frame14 label, .frame15 label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5B5B5B;
    }

.frame12 {
    display: flex;
    align-items: center;
    width: 360px;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
}

.select-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
    margin-right: auto;
}

.caret {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #E0E0E0;
    border-radius: 4px;
}

.vector {
    width: 12px;
    height: 12px;
    background: #1A1A1A;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.frame19 {
    width: 100%;
}
.counter-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 15px;
    padding-right:15px;
    width: 80px;
    justify-content: space-between;
    /* Frame 12 */
    width: 160px;
    height: 48px;
    
}

.count-display {
    font-size: 16px;
    font-family: Arial, sans-serif;
    margin-right: 10px;
}

.button-container {
    display: flex;
    flex-direction: column;
    padding:0px;
}
.button-container1 {
    display: flex;
    flex-direction: column;
    margin-left:250px;
}

.counter-button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 10px;
    padding: 0;
    width: 20px;
    height: 15px;
    text-align: center;
    margin: 1px 0;
    outline: none;
    user-select: none;
}

.counter-button:hover {
    background-color: #e0e0e0;
}

.frame17, .frame18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
}
    .frame18 label,.frame17 label {
        font-family: 'Inter', sans-serif;
        font-size:16px;
        height:24px;
    }
    .frame18 input, .frame17 input {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        height: 24px;
        margin-bottom:10px;
    }

.radio {
    width: 20px;
    height: 20px;
    border: 2px solid #1A1A1A;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}


input[type="radio"]:checked + label .radio {
    background-color: #1A1A1A;
    border-color: #1A1A1A;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}


input[type="radio"]:checked + label {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
    border-color: #1A1A1A;
}


.frame23 {
    width: 360px;
    margin-top: 40px;
}

.frame22 {
    width: 100%;
  /* gap: 12px;*/
    padding:0px; 
    height:80px;

}
.frame22 span{
    margin-right:50px;
}
    .frame22 div {
        width: 360px;
        height: 19px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
.frame23 span {
    /* Your Order Summary: */

    width: 360px;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #5B5B5B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.frame24 {
    width: 712px;
    height: 50px;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.frame27 {
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    
    width: 772px;
    height: 80px;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    margin-top:-90px;
    margin-left:20px;
    margin-left:50px;
    margin-bottom:80px;
}

.frame25 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-right:80px;
}

    .frame25 span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
        margin-left: 10px;
      
    }

    .frame25 div {
        font-weight: 700;
        color: #1A1A1A;
        
    }

.frame26 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 8px;
    width: 59px;
    height: 20px;
    background: #FFF200;
    border-radius: 8px;
    cursor: pointer;
    margin-right:10px;
}

    .frame26 span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1A1A1A;
    }
    .frame26 div{
        width:12px;
        height:12px;
        margin-left:3px;
    }


.tabler-arrow-up {
    width: 24px;
    height: 24px;
}

.vector {
    width: 12px;
    height: 12px;
    border: 1.5px solid #1A1A1A;
    transform: rotate(90deg);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}


/*left stages part*/

.nav-panel {
    box-sizing: border-box;
    width: auto;
    height: 460px;
    /*background: #FDFDFD;*/
   /* border-right: 1px solid #DADADA;*/
   /* padding: 20px;*/
}

.nav-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-left:15px
}

.step {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 44px;
}

.circle {
    /* Frame 1 */
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 1px solid #DADADA;
    border-radius: 20px;
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
}

    .circle.active {
        /* Frame 1 */
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        background: #FFF200;
        border-radius: 20px;
        display: flex; /* Use flexbox for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
    }

    .circle span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: black;
        margin-left:10px;
    }


   /* .circle.active span {
        color: #1A1A1A;
    }*/

.step-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left:10px;

}
    .title.active {
        color: #1A1A1A;
    }

.title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #9A9A9A;
}

.subtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #DADADA;
}
    .subtitle.active {
        color: #5B5B5B;
    }

.help-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    position: relative;
    left: calc(50% - 272px/2);
    width: 262px;
    height: 153px;
    margin-left:10px;
    margin-top:40px;
}



.help-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /*text-align: center;*/
    width: 272px;
    height: 61px;
}
    .help-details h2 {
        /* Having trouble? */

        width: 111px;
        height: 19px;
        margin-left:55px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5B5B5B;
        /* Inside auto layout */
       
    }

.help-title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #5B5B5B;
}

.help-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9A9A9A;
    
}

.contact-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    width: 117px;
    height: 44px;
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #1A1A1A;
}

.vertical-dashed-line {
    display: flex;
    align-items: flex-start;
    width: 20px;
    height: 42px; /* Adjust the height as needed */
    border-left: 1px dashed #5B5B5B; /* Increase the thickness */
    margin-right: 224px;
    position: relative;
}

