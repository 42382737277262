.container-fluid {
    height: 100vh;
}

.reset-pwd-left-content {
    background: #FAF9EB;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#brio-login {
    margin-top: 4%;
}

.content {
    width: 80%;
    margin-top: 60px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #939598;
}

.contact-container {
    margin-bottom: 2%;
    display: flex;
}

.cnt-sprt {
    cursor: pointer;
}

.cnt-sprt-text {
    font-size: 12px;
    cursor: pointer;
    color: black;
    margin-left: 4px;
    margin-top: 4px;
}

.forgot-pwd-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}


.reset-pwd-text {
    width: 210px;
    height: 42px;
    font-weight: 700;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    color: #1A1A1A;
    /* background: #FEF200; */
    border-radius: 6px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.success-message1 {
    color: black;
    font-size: 12px;
    text-align: center;
    padding: 5px;
    background-color: #8BF2A8;
    margin-top:12px;
}

.email-section1 {
    margin-top: 20px;
    width: 100%;
}


.form-lable {
    color: #9A9A9A;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

.input-box {
    width: 100%;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
}

.input-box input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #1A1A1A;
    box-sizing: border-box;
    padding-left: 12px;
}

.submit-bnf {
    width: 100%;
    height: 48px;
    background-color: #FEF200;
    border-radius: 8px;
    margin-top:28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.submit-bn-textf {
    font-weight: 700;
    font-size: 16px;
    line-height: 39px;
    color: #1A1A1A;
    background: transparent;
    border: none;
    cursor: pointer;
}

#brio-logof {
    margin-top: -90px;
    margin-bottom: 130px;
}

.back-to-sign-container{
    margin-top:12px;
}

@media only screen and (max-width: 768px) {
    .reset-pwd-left-content {
        display: none;
    }
    .forgot-pwd-content {
        position: fixed;
        width: 100%;
    }
}