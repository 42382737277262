

.App {
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.maincontainer {
  background: #fdf8f8 ;
}



header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #FDFDFD;
  border-bottom: 1px solid #DADADA;
}

.logo {
  /* b2 3 */

  position: relative;
  width: 112px;
  height: 48px;
  left: 20px;
  border-radius: 2px;
}

.search-container {
  position: absolute;
  width: 445px;
  height: 48px;
  left: calc(50% - 445px / 2 - 281.5px);
  top: 11px;
  left:200px;
  background: #FDFDFD;
  border: 1px solid #DADADA;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.search-input {
  flex: 1;
  height: 100%;
  border: none;
  background: transparent;
  padding: 0 10px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #9A9A9A;
}

.icon-search {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

  .icon-search img {
      width: 100%;
      height: 100%;
  }


.header-buttons {
  display: flex;
  align-items: center;
}

  .header-buttons select,
  .header-buttons.login-button {
      margin-left: 20px;
      padding: 5px 10px;
  }

.featured-services {
  text-align: center;
  display: flex;
  margin-top:80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom:60px;
  margin-left:20px;
  margin-right:20px;
}

  .featured-services h2 {
      /* Frame 27 */
      /* Auto layout */
      padding: 8px;
      margin-bottom: -10px;
      background: #FFF200;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: 'Inter', sans-serif;
  }

  .featured-services p {
      /* Select from our featured Services catered for every businesses. */


      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #1A1A1A;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
  }
.servicesHeading {
  /* featured */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  font-family: 'Inter', sans-serif;
  width: 482px;
  height: 90px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top:40px;
  margin-left:25px;
}

.primary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: auto;
  height: 48px;
  background: #FFF200;
  border: none;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #1A1A1A;
  cursor: pointer;
  margin-left: 70px;
  /* Remove absolute positioning */
}

.icon-container {
  width: 24px;
  height: 24px;
}



.button-text {
  width: 42px;
  height: 20px;
}

/*country selector */

.country-selector {
  position: relative;
  width: 214px;
  background: #FDFDFD;
  border: 1px solid #DADADA;
  border-radius: 8px;
  cursor: pointer;
  
}

.dropdown-header {
  /* Property 1=Country */

  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
 
  width: 214px;
  height: 48px;
 
  background: #FDFDFD;
  border: 1px solid #DADADA;
  border-radius: 8px;
}

.country-icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  border-radius: 12px;
}

.country-name {
  /* Saudi Arabia */

  width: 96px;
  height: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #1A1A1A;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  background:  url('../Assets/dropdownicon.svg') no-repeat center center;
  background-size: contain;
  /*transform: rotate(-90deg);*/
  transition: transform 0.3s;
}

  .arrow-icon.open {
      transform: rotate(180deg);
  }

.dropdown-body {
  position: absolute;
  top: 100%;
  width: 214px;
  background: #FDFDFD;
  border: 1px solid #DADADA;
  border-radius: 8px;
  margin-top: 8px;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  border-top: 1px solid #DADADA;
  cursor: pointer;
}

.check-icon {
  width: 24px;
  height: 24px;
  background: #8BF2A8;
  /*border-radius: 12px;*/
}
.check-icon1 {
  width: 24px;
  height: 24px;
  
}

