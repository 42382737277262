.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9EB;
    margin: 5%;
    margin-top: 9%;
    padding:15%;
    padding-bottom:10%;
    padding-top:2%;
    border-radius: 10px;
    gap:10px;
    box-sizing: border-box;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;

  }
  
  .welcomeHeader {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .checkmarkIcon {
    /* background-image: url('/path/to/checkmark-icon.png'); Replace with actual path */
    background-size: cover;
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
  }
  
  .welcomeTitle {
    /* background-color: #FFEA00; */
    font-weight: bold;
    color:#1A1A1A;
    padding:5px;
    font-size: 25px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
    
  }
  
  .credentialsContainer {
    border: 1px solid #9A9A9A;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 40px;
    background: #F9F9F9;

  }
  
  .credentialsTitle {
    font-weight: 500;
    margin-bottom: 1rem;
    color:#1A1A1A;
  }
  
  .credentialItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .credentialLabel {
    color:#5B5B5B;
  }
  
  .credentialValue {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .copyIcon {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 1rem;
  }
  
  .copyMessage {
    color: green;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  
  .welcomeFooter {
    text-align: left;
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: center;
    gap:5%;
    margin-top: 1.5rem;
    
  }
  
  .exploreButton {
    text-decoration: none; 
    padding: 1% 2%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:5%;
    color:#1A1A1A;
  }
  .exploreButton div{
    font-weight: 500;
    font-size: 15px;
  }
  .loginButton{
    text-decoration: none; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    border-radius: 5px;
    width:100;
    gap: 8px;
    
  }

  
  .exploreButton {
    background-color: #fff;
  }
  
  .loginButton {
    background-color: #FFEA00;
    text-decoration: none; 
    color: #000;
    padding:10px;
  
  }
  .loginButton span{
    text-decoration: none; 
    font-weight: 500;
    font-size: 18px;

  }
@media only screen and (max-width: 768px) {
    .credentialsContainer{
      padding: 5px 5px !important;
    }
  }