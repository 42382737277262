.container-fluid {
    height: 100vh;
}

.login-left-content {
    background: #FAF9EB;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#brio-login {
    margin-top: 4%;
}

.content {
    width: 80%;
    margin-top: 60px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #939598;
}

.contact-container {
    margin-bottom: 2%;
    display: flex;
}


.cnt-sprt {
    cursor: pointer;
}

.cnt-sprt-text {
    font-size: 12px;
    cursor: pointer;
    color: black;
    margin-left: 4px;
    margin-top: 4px;
}

.login-main-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.frame1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.login-text {
    width: 99px;
    height: 42px;
    font-weight: 700;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    color: #1A1A1A;
    /* background: #FEF200; */
    border-radius: 6px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.form-lable {
    color: #9A9A9A;
    display: block;
    margin-bottom: 3px;
    text-align: left;
}


.input-box {
    width: 100%;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
    margin-bottom: 20px;
}

.input-box input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #1A1A1A;
    box-sizing: border-box;
    padding-left: 12px;
}


.login-bn {
    width: 100%;
    height: 48px;
    background-color: #FEF200;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 28px;
}

.login-bn-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    color: #1A1A1A;
    background: transparent;
    border: none;
    cursor: pointer;
}

.frgt-pwd-bn1 {
    margin-top: 10px;
    cursor: pointer;
}

.or-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 16px;
    margin-top: 40px;

}

.or-section .line {
    width: 80px;
    height: 0;
    border: 1px solid #9A9A9A;
}

.or-section .text {
    width: 23px;
    height: 19px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}

.social-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 12px;
    margin-top: 50px;


}

.social-login .google-login,
.social-login .outlook-login {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-login .google-login .text,
.social-login .outlook-login .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #1A1A1A;
}

.signup {
    display: flex;
    justify-content: space-between;
}

.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: white;
    border: 1px solid #dadce0;
    border-radius: 8px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 15px;
    color: #1A1A1A;
    text-decoration: none;
}

.google-logo {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

@media only screen and (max-width: 768px) {
    .login-left-content {
        display: none;
    }
    .login-main-content {
        position: fixed;
        width: 100%;
    }
}

.error-message {
    color: #E85E5E;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: left; 
}

.input-error-message {
    color: #E85E5Eed;
    font-size: 12px;
    margin-top: 1px;
    margin-left:1px;
    text-align: left; 
}

  
  
  