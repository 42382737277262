/* .cart-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 30px 50px 30px 30px;
    background-color: #faf7f7;
  
}
.card-main-container{
    overflow: scroll;
    height: 80vh;
}
.card-main-container::-webkit-scrollbar {
    width: 4px;
}
.card-main-container::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}
.RenewSubForm-S1title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    height: 35px;
    background: #FFF200;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    text-align: center;
    color: #1A1A1A;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 5px;
}

.price-details,.cart-details {
    background-color: #faf7f7;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}




.cart-detail-item {
    padding: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
   
}
.cart-detail-item1 {
    padding: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    border: 1px solid #dadada;
   
}

.continue-button {
    background-color: #FFF200;
    color: black;
    padding: 10px 20px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.price-info {
    margin-top: 20px;
}

.price-row, .price-row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
}

.total-amount {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 105px;
}

.savings {
    color: green;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
}
.cart-detail-item-Add{
border: 1px solid #dadada;
align-items: center;
box-sizing: border-box;
display: flex;
flex-direction: row;
gap: 16px;
justify-content: space-between;
padding: 16px;
width: 100%;
background-color: white;
margin-top: 10px;
}
.cart-detail-item-Add1{
    border: 1px solid #dadada;
    
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    }
.C-Label {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #5B5B5B;
    flex: 0 0 auto;
}
.C-Value {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #1A1A1A;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1%;
}
.C-Label1 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #5B5B5B;
    flex: 0 0 auto;
    padding-left: 20px;
}
.C-Value1 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #1A1A1A;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1%;
}
.cart-detail-item-btn{
    border: 1px solid #dadada;
    
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
    padding: 16px;
    width: 100%;
    background-color: white;
    
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
    .truck-icon1{
        width: 30px;
        height: 30px;
        margin-left: 15px;
    } */




    .cart-subscriptions-container {
        background-color: #faf9ec;
        padding: 40px;
        margin: 0 auto;
        height:100vh;
    }
    
    .cart-title-adjust {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }
    
    .cart-title-box {
        font-family: 'Inter', sans-serif;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        padding-bottom: 0;
        gap: 8px;
        /* background: #FFF200; */
        margin-bottom: 10px;
        text-decoration: underline;
        text-decoration-color: #FFF200;
        text-underline-offset: 8px;
        text-decoration-thickness: 3px;
    }
    
    .cart-title {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #1A1A1A;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .sub-titl{
        font-family: 'Inter', sans-serif;
        
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #1A1A1A;
       
    }
    .cart-subscriptions-box {
        background-color: white;
        border-radius: 10px;
        /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
        padding: 2%;
        border: 1px solid lightgray;
    }
    
    .cart-section {
        margin-bottom: 30px;
    }
    
    .cart-header {
        display: flex;
        background: #F1F1F1;
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: #DADADA;
        border-radius: 8px 8px 0 0;
        padding: 10px;
        justify-content:space-between;
        text-align:left;
        min-width: 1100px;
    }
    .cart-data-container{
        overflow-y: scroll;
        height: 50vh;
    }
    
    .cart-header-item-service {
     
        padding-left: 15px;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        color: #1A1A1A;
        text-align:left;
    }
    
    .cart-header-item {
       
        text-align: center;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        color: #1A1A1A;
        
    }
    .cart-header-item1 {
        
        text-align: center;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        color: #1A1A1A;
        padding-left: 140px;
    }
    .cart-header-item2{
        text-align: center;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        color: #1A1A1A;
        padding-right:5px;
    }
    .cart-subscription-item {
        display: flex;
        align-items: center;
        padding: 15px;
        border: 1px solid #e0e0e0;
        min-width: 1100px;
    }
    
    .cart-service-details {
        display: flex;
        align-items: center;
        flex: 1.5;
        padding-left: 10px;
    }
    .details-p {
        margin: 0; /* Remove any default margin */
        padding: 0; /* Remove any default padding */
    }
    
    .details-p p {
        margin: 2px 0; /* Adjust margin to reduce the gap between paragraphs */
        padding: 0;
    }
    
    
    .cart-purchased-licenses,
    .cart-next-billing-date,
    .cart-actions {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
    }
    
    .cart-service-icon {
        width: 50px;
        height: 50px;
        margin-right: 15px;
    }
    
    .cart-service-name {
        font-size: 16px;
        font-family: 'Inter', sans-serif;
    }
    
    .cart-service-plan {
        font-size: 14px;
        color: #888;
        font-family: 'Inter', sans-serif;
    }
    
    .cart-license-count,
    .cart-expiring-on,
    .cart-billing-date {
        flex: 0.8;
        text-align: center;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        text-align:left
    }
    .cart-expiring-on
     {
        padding-left:70px
    }
    .cart-license-count{
        padding-left:60px
    }
    .cart-billing-date1 {
        flex: 0.8;
        text-align: center;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        text-align:left
    }
    .cart-order-type{
        flex: 0.8;
        text-align: center;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        text-align:left
    }
    
    .cart-actions {
        display: flex;
        justify-content: center;
        font-family: 'Inter', sans-serif;
        text-align:left;
        gap: 13%;
    }
    .cart-S1frame26 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        
    }
    .cart-S1frame26 {
        display: flex;
        flex-direction: row;
      
        padding: 14px 20px;
        gap: 8px;
        /* width: 59px;
        height: 20px; */
        background: #FFF200;
        border-radius: 8px;
        cursor: pointer;
        /* margin-right: 10px; */
    }
    .cart-S1frame26 div {
        width: 12px;
        height: 12px;
        margin-left: 3px;
    }

    .no-subscriptions {
        text-align: center;
        padding: 40px;
        box-sizing: content-box;
        border: 1px solid #e0e0e0;
        color:#e25555;
    }
    
@media (max-width: 768px) {
    .cart-subscriptions-container{
        margin-top: 15%;
    }
}