.App {
    font-family: Arial, sans-serif;
    width: 100%;
    height: 100%;
   /* display: flex;*/
    flex-direction: column;
}


.LPApp {
    font-family: Arial, sans-serif;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.LPMainContainer {
    background: #F9F9F9;
    margin-top: 5%;
}

.LPHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FDFDFD;
    border-bottom: 1px solid #DADADA;
}
.LPnavbar{
position: fixed;
  top: 0;   
  left: 0; 
  width: 100%;
  background-color: white; 
  z-index: 1000; 
}

.LPLogo {
    position: relative;
    width: auto;
    height: 48px;
    left: 20px;
    border-radius: 2px;
}



.LPSearchInput {
    flex: 1;
    height: 100%;
    border: none;
    background: transparent;
    padding: 0 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
    outline: none;
}

.LPIconSearch {

    /* width: 24px;
    height: 24px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.LPIconSearch img {
    width: 100%;
    height: 100%;
}



  .LPdropdown-header {
    /* Property 1=Country */

    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    width: 180px;
    height: 42px; 

    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
  }

  .LPcountry-icon {
    width: 30px;
    height: 30px;
    background-size: 24px 24px !important;
    background-position: center;
    background-repeat: no-repeat;
}

  .LPcountry-name {
    /* Saudi Arabia */

    /* width: 0px; */
    height: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    overflow: visible;
    color: #1A1A1A;
  }

  .LParrow-icon {
    width: 24px;
    height: 24px;
    background:  url('../Assets/dropdownicon.svg') no-repeat center center;
    background-size: contain;
    /*transform: rotate(-90deg);*/
    transition: transform 0.3s;
  }

    .LParrow-icon.open {
        transform: rotate(180deg);
    }



  .LPcheck-icon {
    width: 24px;
    height: 24px;
    background: #8BF2A8;
    /*border-radius: 12px;*/
  }
  .LPcheck-icon1 {
    width: 24px;
    height: 24px;

  }

.LPFeaturedServices {
    text-align: center;
    display: flex;
    flex-direction: column;
    width:100%;
    padding-top:3%;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.PFServicesHeading {
    display: flex;
    justify-content: center ;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-bottom:1%;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: auto;
    margin-top: 45px;
}
.PFServicesHeading span{
    /* background:#FFF200; */
    padding: 8px 13px;
    font-size: 24px;
    font-weight:550;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

@media only screen and (max-width: 768px) {
    .PFServicesHeading span{
        font-size: 20px;
    }
}


.PFServicesHeading p{
    font-size: 14px;
    margin-bottom: 0px;
}

.LPPrimaryButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4% 6%;
    gap: 8px;
    background: #FFF200;
    border: none;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
    cursor: pointer;
    margin-left: 5%;
}

.LPIconContainer {
    width: 24px;
    height: 24px;
}

.LPIcon {
    width: 100%;
    height: 100%;
}



.LPframe51 {
    text-decoration: none; /* Ensure no underline for the link */
    display: flex;
    align-items: center;
    gap: 8px;
    color: inherit; /* To maintain the original color, if not, you can set a custom color */
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    padding: 12px 16px;
}

.LPframe51 div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-icon {
    /* Any specific styling for the profile icon */
}

.LPframe51 span {
    text-decoration: none; /* Additional check */
}


/*CARD CSS ********************************/

/* Ensure .card is positioned relative to contain absolutely positioned children */
    .card-header part1 {
        /* Frame 65 */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        background: blue;
}

.features {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.feature img {
    width: 100%;
}
/* .feature img{
    width:90%;
    height:90%;
} */

.Descriptions {
    /* Frame 70 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    margin-left:10px;
    /* gap: 8px; */
    /* position: absolute;
    width: 341px;
    height: 147px;
    left: 20px;
    top: 432px; */
}

.description span {
    text-align: left;
}

.description p {
    /* Lorem ipsum dolor sit amet consectetur. Non et phasellus quis habitant odio tincidunt at tincidunt neque. */
    display: flex;
    height: auto;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 17px; */
    color: #5B5B5B;
    text-align: left;
}

/* Vector (Arrow Icon) */
.CDarrow-icon {
    width: 34px;
    height: 34px;
}

/* Frame 65 */
.frame-65 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}


/*SUBSCRIPTION STAGE 1 CSS ************************************ */

.S1row {
    display: flex;
    width: 100%;
}
/* .maincontent1{
    margin-top:40px;
} */

.S1col-4 {
    width: 20% !important;
    padding: 2px;
    /* margin-top: 6%; */
    box-sizing: border-box;
    margin-top: 80px;
    /* height: 87vh; */
    border-right: 1px solid #DADADA;
}

.S1col-8 {
    width: 80% !important;
    height:100vh;
    padding: 20px;
    overflow-y: scroll;
    overflow-x:hidden ;
    background: #F9F9F9;
}

@media (max-width: 768px) {
    .S1col-8 {
      margin-top: 24%;
      width: 100% !important;
      padding: 20px 0px;
      overflow-y: visible;
      overflow-x: visible;
    }
}
@media (max-width: 768px) {
    .S1col-4 {
      width: 100% !important;
    }
  }
.S1col-8::-webkit-scrollbar {
    width: 4px;
}
.S1col-8::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}
.S1subscription-form{
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.S1form-heading {
    /* Frame 12 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    /*padding: 40px;*/
    gap: 16px;

    width: auto;
    height: 70px;
}

    .S1title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        height: 35px;
        background: #FFF200;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        /* identical to box height */
        text-align: center;
        color: #1A1A1A;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .S1form-heading p {
        margin-top: -10px;
    }


.S1steps {
    display: flex;
    flex-direction: column;
    /* border-right: 1px solid #ddd;*/
    padding-right: 20px;
}

.S1step {
    display: flex;
    align-items: center;
    /*margin-bottom: 20px;*/
}

    .S1step span {
        display: inline-block;
        /* width: 24px;
        height: 24px; */
        /* background-color: #ddd;*/
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
    }

    .S1step.active span {
        background-color: #ff0;
    }


.S1contact-us-button {
    background-color: #ff0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
}

h2 {
    margin-top: 0;
}

.S1form-group {
    margin-bottom: 20px;
}

.S1radio-group {
    display: flex;
    flex-direction: column;
}

    .S1radio-group input {
        margin-right: 10px;
    }

.S1order-summary {
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.S1payment-due {
    font-weight: bold;
}

/*.next-button {
    background-color: #ff0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
}
*/


.S1fields {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    align-items: flex-start;
    width: 93%;
    height: auto;
    margin: 0 auto;
    padding: 0 5% 1% 5%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    box-sizing: border-box;
    margin-left: 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.S1frame21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* width: 400px; */
    height: auto;
    margin-top: 30px;
    box-sizing: border-box;
}

.S1frame13, .S1frame14, .S1frame15, .S1frame19, .S1frame17, .S1frame18, .S1frame22, .S1frame23, .S1frame24, .S1frame25, .S1frame26, .S1frame27 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.S1frame13, .S1frame14, .S1frame15 {
    width: 100%;
}

    .S1frame13 label, .S1frame14 label, .S1frame15 label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        color: #5B5B5B;
    }

.S1frame12 {
    display: flex;
    align-items: center;
    /* width: 360px;
    height: 48px; */
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
}
.S1billingSelector{
    display: flex;
    flex-direction: column;
    gap:8px;
}
.dropdown-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.custom-select {
    width: 170px;
    padding: 10px;
    background-color: #f9f9f9; /* Background color similar to the one in the image */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Slightly rounded edges */
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #333;
    appearance: none; /* Removes default styling */
    -webkit-appearance: none; /* Safari */
    -moz-appearance: none; /* Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 20px;
  }
  .custom-select.customer{
    width: 300px ;
  }

  @media (max-width: 768px) {
    .custom-select.customer {
        width: 230px;
        font-size: 13px;
    }
    .custom-select{
        padding: 6px;
    }
  }
  
  .custom-select:focus {
    outline: none;
    border-color: #cccccc; /* Focused border color */
  }
  
  .custom-select option {
    padding: 10px;
    background-color: white;
    color: black;
  }
  
  .custom-select:hover {
    background-color: #f1f1f1; /* Hover effect */
  }
  
  .custom-select option:checked {
    background-color: #dfe6e9; /* Background color for selected option */
  } 

.drop-down-box {
    display: grid;
    gap: 10px;
}
.drop-down-box select {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.drop-down-box option {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.div-row {
    display: flex;
    gap: 30%;
    width: 100%;
}

.icon-container {
    position: absolute;
    right: 10px;
    pointer-events: none; /* Ensure the icon doesn't interfere with dropdown interactions */
}

.custom-dropdown-icon {
    width: 16px; /* Adjust the size of the icon */
    height: 16px;
}



.S1select-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
    margin-right: auto;
}

.S1caret {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #E0E0E0;
    border-radius: 4px;
}

.S1vector {
    width: 12px;
    height: 12px;
    background: #1A1A1A;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.S1frame19 {
    width: 100%;
}

.S1counter-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding:2%;
    justify-content: space-between;
    /* Frame 12 */
    width: 50%;
    height: 100%;
}

.S1count-display {
    font-size: 16px;
    font-family: Arial, sans-serif;
    margin-right: 10px;
}

.S1button-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.S1button-container1 {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
}

.S1counter-button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 10px;
    padding: 0;
    width: 20px;
    height: 15px;
    text-align: center;
    margin: 1px 0;
    outline: none;
    user-select: none;
}

    .S1counter-button:hover {
        background-color: #e0e0e0;
    }

.S1frame17, .S1frame18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
}

    .S1frame18 label, .S1frame17 label {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        height: 12px;
    }

    .S1frame18 input, .frame17 input {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        height: 24px;
        margin-bottom: 10px;
    }

.S1radio {
    width: 20px;
    height: 20px;
    border: 2px solid #1A1A1A;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}


input[type="radio"]:checked + label .radio {
    background-color: #1A1A1A;
    border-color: #1A1A1A;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}


input[type="radio"]:checked + label {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
    border-color: #1A1A1A;
}


.S1frame23 {
    /* width: 360px; */
    margin-top: 5%;
}

.S1frame22 {
    width: 100%;
    /* gap: 12px;*/
    padding: 0px;
    /* height: 80px; */
}

    .S1frame22 span {
        margin-right: 50px;
    }

    /* .S1frame22 div {

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    } */
@media (max-width: 768px) {
    .S1frame22 {
        margin-bottom: 6px;
    }
    .S1frame22 div {
        width: 100%;
        overflow-wrap:anywhere;
    }
  
}

.S1frame23 span {
    /* Your Order Summary: */

    width: 360px;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #5B5B5B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.S1frame24 {
    /* width: 712px;
    height: 50px; */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.S1frame27 {
    display: flex;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    /* gap:350px; */
}

.S1frame25 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    /* margin-right: 80px; */
}

    .S1frame25 span {
        display: flex;
        width:100%;
        align-items:flex-start;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
        /* margin-left: 10px; */
    }

    .S1frame25 div {
        font-weight: 700;
        color: #1A1A1A;
    }

.S1frame26 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* justify-content: center; */
    align-items: center;
    padding: 14px 20px;
    gap: 8px;
    /* width: 59px;
    height: 20px; */
    background: #FFF200;
    border-radius: 8px;
    cursor: pointer;
    /* margin-right: 10px; */
}

    .S1frame26.disabled {
        pointer-events: none; /* Disable clicks */
        opacity: 0.6; /* Reduce visibility */
        cursor: not-allowed; /* Change cursor to indicate disabled state */
    }

    .S1frame26 span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1A1A1A;
    }

    .S1frame26 div {
        width: 12px;
        height: 12px;
        margin-left: 3px;
    }


.S1tabler-arrow-up {
    width: 24px;
    height: 24px;
}

.S1vector {
    width: 12px;
    height: 12px;
    border: 1.5px solid #1A1A1A;
    transform: rotate(90deg);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}


/*left stages part*/

.LPnav-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
}


.LPnav-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 18px;
    margin-left: 15px;
}

.LPstep {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 32px;
}

.LPcircle {
    /* Frame 1 */
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid #DADADA;
    border-radius: 20px;
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
}

    .LPcircle.active {
        /* Frame 1 */
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        background: #FFF200;
        border-radius: 20px;
        display: flex; /* Use flexbox for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
    }

    .LPcircle span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #DADADA;
        /* margin-left: 10px; */
    }
        .LPcircle span.active {
            color: #1A1A1A;
        }

.LPstep-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.LPtitle.active {
    color: #1A1A1A;
    font-size: 14px;
}

.LPtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #9A9A9A;
}

.LPsubtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #DADADA;
}

    .LPsubtitle.active {
        color: #5B5B5B;
        font-size: 12px;
    }

.LPvertical-dashed-line {
        display: flex;
        align-items: flex-start;
        width: 20px;
        height: 35px; /* Adjust the height as needed */
        border-left: 1px dashed #5B5B5B; /* Increase the thickness */
        /* margin-right: 224px; */
        margin-left:15px;
        position: relative;
    }

.LPhelp-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    /* position: relative; */
    /* left: calc(50% - 272px/2); */
    /* width: 262px;
    height: 153px; */
    margin-left: 15px;
    margin-top:15%;
}
.LPhelp-section-addlicences {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    /* position: relative;
    left: calc(50% - 272px/2); */
    /* width: 262px;
    height: 153px; */
    margin-left: 10px;
    margin-top: 70%;
}

.LPhelp-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /*text-align: center;*/
    /* width: 272px;
    height: 61px; */
}

    .LPhelp-details h2 {

        margin-left: 55px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5B5B5B;
        /* Inside auto layout */
    }

.LPhelp-title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #5B5B5B;
}

.LPhelp-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    color: #9A9A9A;
}

.LPcontact-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:8px 12px;
    gap: 8px;
    /* width: 117px;
    height: 44px; */
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #1A1A1A;
    background:#FAF9EB;
    border: 1px solid #DADADA;
}


/*STAGE 2 ************************************/


.main-content {
    height: auto;
    padding:30px;
    padding-top:0px;

}

.CA-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;

}

    .CA-header div {
        /* Frame 27 */
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        height: 35px;
        background: #FFF200;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        /* identical to box height */
        text-align: center;
        color: #1A1A1A;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }


.create-account-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    background: #fff200;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #1a1a1a;
    border: none;
    cursor: pointer;
}

.header-text {

    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1a1a1a;
}

.form-container {
    background: #fdfdfd;
    border: 1px solid #dadada;
    border-radius: 16px;
    padding:20px 40px 20px 40px;
    max-width:100%;
    gap:15px;
}

.form-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.form-group {
    flex: 0 0 48%; /* Takes up approximately 48% of the width to fit two columns with gap */
    display: flex;
    flex-direction: column;
    gap:8px;
    width: 100%;
}

.label {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
}

.input-field {
    /* height: 48px; */
    width:100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #1A1A1A /*#E0E0E0*/;
}
    .input-field::placeholder {
        color: #E0E0E0; /* Replace with your desired color */
    }

.phone-input {
    display: flex;
    align-items: center;
    position: relative;
}


.phone-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    overflow: hidden;
}
.country-code{
    padding: 10px;
    background-color: #E0E0E0;
    border-radius: 4px 0px 0px 4px;
}
.country-code img{
   margin-bottom: 18%;
}

.country-code-dropdown {

    border: none;
    background-color:#E0E0E0;
    /* padding: 10px; */
    outline: none;
    font-size: 14px;
    font-family: inherit;
    appearance: none; /* Remove default arrow */
    /* border-right: 1px solid #ccc; */
    border-radius: 4px 0 0 4px; /* Rounded corners for the left side */
    color: #555;
}

.phone-number {
    border: none;
    flex: 1;
    /* padding: 10px; */
    font-size: 14px;
    font-family: inherit;
    border-radius: 0 4px 4px 0; /* Rounded corners for the right side */
}

.phone-number:focus {
    outline: none;
}

.phone-input:focus-within {
    border-color:  #f7f7f7; /* Change border color when focused */
}

.next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 8px;
    position: absolute;
    /* width: 113px;
    height: 52px; */
    left: 807px;
    top: 550px;
    background: #fff200;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.next-button-text {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #1a1a1a;
}

.arrow-icon1 {
    width: 24px;
    height: 24px;
    border: 1.5px solid #1a1a1a;
    transform: rotate(90deg);
}

.CA-frame26 {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    gap: 8px;
    /* width: 69px;
    height: 20px; */
    background: #FFF200;
    border-radius: 8px;
    margin-top:20px;
    cursor: pointer;
    /* margin-top:60px;
    margin-left:550px; */
}
.CA-frame26cont{
    display:flex;
    justify-content:space-between;
    width:100%;
    /* margin-left: 4%; */
}
.CA-arrow-icon {
    width: 14px;
    height: 14px;
    background: url('../Assets/dropdownicon.svg') no-repeat center center;
    background-size: contain;
    /*transform: rotate(-90deg);*/
    transition: transform 0.3s;
}



/*STAGE 3 CSS **********************************************/

.S3main-content {
    display: flex;
    flex-direction: column;
    height: auto; /* Ensure main content takes up the full viewport height */
    width: 100%;
}

.S3frame-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 4% 0 4%; */
    height: auto;
}
@media only screen and (max-width: 768px) {
    .S3Fields{
        padding: 0%;
    }
  }

.S3Fields {
    flex-grow: 1; /* Allow this section to grow and fill available space */
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background: #FDFDFD;
    border-radius: 16px;
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative; /* Positioning context for the sticky footer */
    width: 100%;
    max-height: calc(100vh - 80px); /* Adjust height to leave space for footer */
}

@media only screen and (max-width: 768px) {
    .S3Fields{
        padding: 9px;
    }
  }


.S3Fields::-webkit-scrollbar {
    width: 4px;
}

.S3Fields::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}

.S3frame-27 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 50px; */
    /* margin-bottom: 50px; */
}
.S3frame-27 span{
    background-color: #FFF200;
    font-size: 28px;
    padding:8px;
    font-weight: 650;
}

.S3FrameItem {
    flex: 1;
    flex-direction: row;
}

.S3FrameMiddle {
    display: flex;
}
.S3FrameItem{
    flex:1;
    flex-direction:row;
}
.S3FrameMiddle{
    display: flex;

}
.S3FrameItem{
    flex:1;
}

.S3review-and-place-order {
    /* width: 340px; */
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 15px;
    text-align: center;
    color: #1A1A1A;
}

.S3check-fields {
    /* width: 410px;
    height: 19px; */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}

.S3Frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #DADADA;
    justify-content: space-between;
    border-radius: 16px;
    box-sizing: border-box;
}

.S3Frame1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.S3Framecolumn {
    display:flex;
    flex-direction:row;
    width: 100%;
    justify-content:space-between;
    word-wrap: break-word;
}
/* .S3Framecolumn{
    flex: 3;
    width:100%;
    justify-content:flex-end;
} */
.S3FrameAmount span{
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 650;
}


.S3Row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
}

.S3RowSpaceBetween {
    justify-content: space-between;
}

.S3Label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #5B5B5B;
    flex: 0 0 auto;
    max-width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
}

.S3Value {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #1A1A1A;
    flex: 1;
    align-items:flex-end;
    justify-content:flext-end;
    padding-left: 5px;
}

.S3Footer {
    position: sticky;
    bottom: 0;
    width: calc(100%); /* Adjust width to fit within padding if needed */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    padding: 16px;
    box-sizing: border-box;
    border-radius:10px;
    z-index: 999;
}

.S3FooterContent {
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.S3PlaceOrderButton {
    padding: 14px 20px;
    background: #FFF200;
    border: none;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #1A1A1A;
    cursor: pointer;
}

.S3PlaceOrderButton.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}
.S3popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it overlays everything else */
    display: flex;
    align-items: center;
    justify-content: center;
}




/*#popup*/


.S3popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px); /* Apply blur effect to the background */
    z-index: 1000; /* Ensure it is above other content */
}

.S3popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 400px;
    height:500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.S3popup-close {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

    .S3popup-close:hover {
        background-color: #0056b3;
    }






.S3ModalContainer {
    display: flex;
    flex-direction: column;
    width:400px;
    height:560px;
    max-width: 100%;
    max-height: 100%;
    background-color: #FDFDFD;
    border-radius: 16px;
    box-shadow: 0px -5.8px 17.9px rgba(26, 26, 26, 0.048), 0px -1.7px 5.3px rgba(26, 26, 26, 0.08);
    overflow: hidden;
}

.S3ModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #FAF9EB;
    border-bottom: 1px solid #E0E0E0;
}

.S3LogoButton {
   /* display: flex;
    align-items: center;
   */
    /*padding: 12px 16px;*/
    /*border-radius: 8px;*/
}

.S3Logo {
    color: #FFF200;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
}

.S3CloseButton {
    cursor: pointer;
}

.S3ModalContent {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.S3PaymentMethod {
    margin-bottom: 16px;
}

.S3MethodName {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    margin-bottom: 8px;
}

.S3MethodOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
}

.S3OptionIcon {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    display:flex;
    flex-direction:row;
    gap:5px;
    align-items:center;
    color: #000000;
}

.S3OptionCheck {
    width: 24px;
    height: 24px;
    border: 1px solid #DADADA;
    border-radius: 50%;
    position: relative;
    overflow: hidden; /* Ensure the image stays within the circle */
    background-color: #ffffff; /* Optional: To ensure a white background inside the circle */
}

.S3OptionCheck img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire circle */
}

.S3OptionChecks {
    display: flex;
    align-items: center;
}

.S3OptionCheck:not(:first-child) {
    margin-left: -12px; /* Adjust for 50% overlap */
}





.S3ModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #FDFDFD;
    border-top: 1px solid #E0E0E0;
}

.S3AmountDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.S3Amount {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.S3Details {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #5B5B5B;
}

.S3PayButton {
    padding: 12px 24px;
    background-color: #FFF200;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #1A1A1A;
    cursor: pointer;
}

.S3paymenttypeicon {
    /* Frame 55 */

    width: 32px;
    height: 32px;
    background: #FAF9EB;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}


 /*MY SUBSCRIPTIONS CSS ************************************************************/



/* Container */

.ms-title-box {
    font-family: 'Inter', sans-serif;
    /* Frame 28 */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    /* width: 291px;
    height: 35px; */

    /* background: #FFF200; */
    margin-bottom:20px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}
.ms-title-adjust{
    display:flex;
    justify-content:center;
    margin-top: 5%;
}


/* Title */
.ms-title {

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
    color: #1A1A1A;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* Subscriptions Box */
.ms-subscriptions-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2%;
}

/* Section Header */
.ms-section {
    margin-bottom: 30px;
}

.ms-section-title {
    font-size: 18px;
    margin-bottom: 15px;
    font-family: 'Inter', sans-serif;
}

/* Headers */
.ms-header {
    display: flex;
    background: #F1F1F1;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #DADADA;
    border-radius: 8px 8px 0 0;
    padding: 10px;
   /* margin-bottom: 10px;*/
    font-weight: bold;
}

.ms-header-item {
    flex: 0.8;
    text-align: center;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}
.ms-header-item-service {
    flex: 1.5;
    text-align: center;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #1A1A1A;
}

/* Subscription Item */
.ms-subscription-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #e0e0e0;
   height:50px;
}

   /* .ms-subscription-item.expired {
        background-color: #f7f7f7;
    }
*/
/* Service Details */
.ms-service-details {
    display: flex;
    align-items: center;
    flex: 1.5;
}

.ms-service-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.ms-service-name {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}

.ms-service-plan {
    font-size: 14px;
    color: #888;
    font-family: 'Inter', sans-serif;
}

.ms-license-count, .ms-expiring-on, .ms-billing-date {
    flex: 0.8;
    text-align: center;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.ms-actions {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    flex: 0.8;
    gap:13%;
}

.ms-action-button {
    background-color: #f9d702;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
}

/* Explore Services */
.ms-explore-services {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px;
}

@media (max-width: 768px) {
    .ms-explore-services   {
        flex-direction: column;
    }
  }
.ms-explore-services p{
    font-weight: 500;
    font-size:20px;
    color:#1A1A1A;
}

.ms-explore-button {
    background-color: #FFF200;
    color:#1A1A1A;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    justify-content: center;
    margin-top: 8%;
}

@media (max-width: 768px) {
    .ms-explore-button   {
        margin-top: 1%;
    }
  }

.order-continue-button {
    background-color: #FFF200;
    color:#1A1A1A;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    justify-content: center;
}

/* Frame 1000003257 */
.ms-frame-1000003257 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    /* width: 1240px;
    height: 240px; */
}



.ms-service-details,
.ms-purchased-licenses,
.ms-next-billing-date,
.ms-actions {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}



/* Content */
.ms-frame-1000003254 {
    /* width: 1240px;
    height: 96px; */
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #DADADA;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
}

/* Logos and vectors */
.ms-frame-1000003256 {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 16px;
    /* width: 339px;
    height: 80px; */
    position: relative;
}

.ms-frame-53 {
    /* width: 80px;
    height: 80px; */
    background: #F9F9F9;
    border-radius: 8px;
    position: relative;
}

.ms-logos-microsoft-icon {
    /* width: 48px;
    height: 48px; */
    position: absolute;
    left: 16px;
    top: 16px;
}

.ms-vector-orange, .ms-vector-green, .ms-vector-blue, .ms-vector-yellow {
    position: absolute;
    /* width: 12px;
    height: 12px; */
}

.ms-vector-orange {
    background: #F1511B;
}

.ms-vector-green {
    background: #80CC28;
}

.ms-vector-blue {
    background: #00ADEF;
}

.ms-vector-yellow {
    background: #FBBC09;
}

/* Text */
.ms-service-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}

.ms-plan-details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5B5B5B;
}

/* Icons */
.ms-action-add {
    width: 24px;
    height: 24px;
    background: #FFF200;
    border-radius: 12px;
}

.ms-action-refresh {
    width: 24px;
    height: 24px;
    background: #9A9A9A;
    border-radius: 12px;
}

.ms-status {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
}

.ms-x01, .ms-x02 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
}


/*SIGNUP PAGE CSS **********************************************/

/* SignupPage.css */

.spContainer {
    width: 100%;
    background: #faf9eb;
    border-radius: 12px;
}

.spRow {
    display: flex;
    height: 100%;
}

.spCol8 {
    flex: 2;
    display: flex;
    flex-direction: column;
   /* justify-content: center;*/
    align-items: flex-start; /* Align items to the start (left side) */
    padding: 40px; /* Add some padding for better spacing */
    height: 100vh;
}

.spbriologo {
    align-self: flex-start; /* Align the logo to the top left */
    margin-bottom: 20px; /* Add some space below the logo */
}

.spHeadingSection {
    text-align: left;
}

.spHeading {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #939598;
    padding-top:100px;
}

.spCol4 {
    flex: 1;
    background: #fdfdfd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px;
}

.spSignupSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.spSignupButton {
    /* width: 180px;
    height: 55px; */
    background: #fff200;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    color: #1a1a1a;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}

.spForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.spInputGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.spLabel {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5b5b5b;
}

.spInput {
    width: 85%;
    height: 38px;
    background: #fdfdfd;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding-left: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #e0e0e0;
}

.spSubmitButton {
    /* width: 290px;
    height: 52px; */
    background: #fff200;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #1a1a1a;
    cursor: pointer;
    gap: 8px;
}

.spButtonIcon {
    margin-right: 8px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 45%;
    text-align: left;
}

.gst-lable{
    color: #686868;
}

.radioB{
    margin-right: 5px;
}

.input-wrapper{
    margin-left: 3%;
}

.modal-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}

.button {
    padding: 10px 20px;
    background-color: #FFF200;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.GST-popup{
    text-decoration: underline;
    margin-top: 20px;
    cursor:pointer;
}

.GSTinput-field {
    /* height: 48px; */
    width:70%;
    background: #fdfdfd;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: #1A1A1A /*#E0E0E0*/;
}

.GSTradio-lable{
    padding: 8px 0px 8px 0px;
}

.S1count-input{
    border: none;
    outline: none;
    width: 100%;
}

.spinner {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(255, 242, 0);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}
.preloader-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-message {
    margin-top: 16px;
    font-size: 16px;
    color: #fff874;
    text-align: center;
}

.waittime-disabled {
    pointer-events: none; /* Disables interaction for all elements */
    opacity: 0.5; /* Makes elements appear grayed out */
    background-color: rgba(128, 128, 128, 0.2); /* Optional: Background color for the disabled effect */
}  

.view-plan-button {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    min-width: 180px;
  }
  
  .ms-icon {
    width: 25px; /* Adjust size as needed */
    height: 25px; /* Adjust size as needed */
    margin-right: 10px;
  }
  
  .view-plan-button:hover {
    border-color: #888;
    background-color: #f9f9f9;
  }
  
.ItemDetails-heading {
    margin-top: 2%;
}

.ItemName {
    margin-top: 5%;
    font-weight: 500;
    font-size: larger;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 768px) {
    .ItemName.sub_item{
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .view-plan-button.sub_button{
        margin-bottom: 15px;
        width:100%;
    }
}
.info-icon {
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    font-size: 18px; /* Adjust icon size */
    color: #666; /* Subtle color for info icon */
}

.info-icon:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-text {
    visibility: hidden;
    background-color: #333; /* Black background for tooltip */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%); /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    white-space: nowrap; /* Ensure no wrapping */
}

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent; /* Black arrow */
}
@media (max-width: 768px) {
    .tooltip-text {
        font-size: 7px;
        left:-250%;
    }
    .tooltip-text::after{
        left: 82%;
    }
}

.TUP-button {
    display: flex;
    align-items: center;
    padding: 4px 10px 4px 7px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #FFF200;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}

.TUP-button:hover {
    border-color: #ccc;
    background-color: #dcd100;
}

.login-heading {
    font-size: 14px;
    color: gray;
}
  
.login-form {
    display: flex;
    flex-direction: row;
    gap: 22.72px;
}
  
.form-group {
    margin-bottom: 15px;
    text-align: left;
}
  
label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}
  
.input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}
  
.input-field::placeholder {
    color: #333;
}

.login-btn {
    background-color: #FFF200;
    color: #000;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    width: 161px;
}

.login {
    display: flex;
    justify-content: flex-end;
}

.sidebar {
    display: block;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      display: none;
      transition: all 0.3s ease;
      position: absolute;
      width: 100%;
      z-index: 1000;
      background-color: white;
      height: 100%;
    }
    
    .sidebar.open {
      display: block;
    }
  
    .hidden-content {
      display: none;
    }
  
    .toggle-sidebar-btn {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1100;
    }
  } 

.hamburger-icon {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25px;
    height: 18px;
    cursor: pointer;
    padding-right: 15px;
    margin-bottom:5px;
  }
  
  .bar {
    width: 26px;
    height: 4px;
    background-color: #2c2a2a;
    border-radius: 2px;
    transition: 0.3s;
  }
  
  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  @media only screen and (max-width: 768px) {
    .hamburger-icon {
      display: flex;
      align-items: center;
    }
    .bar {
      display: block;
      align-items: center;
    }
    .maincontent .row{
        margin:0px;
    }
    .S1frame23 span{
        width: auto !important;
    }
    .S1fields{
        width:100%;
        margin:0px;
    }
    .form-group{
        flex:none;
    }
    .modal-content{
        width:95%;
    }
    .S3Frame1{
        flex-direction: column !important;
    }
    .S3Value{
        font-size: 13px;
    }
    .ItemName{
        flex-direction: column-reverse;
        gap:10px;
    }
    .item-card{
        width:90%;
    }
  }
.S1col-8.new_sub{
    width: 80% !important;
    height:100vh;
    padding: 20px;
    overflow-y: scroll;
    overflow-x:hidden ;
    background: #F9F9F9;  
}
.S1col-8.new_sub::-webkit-scrollbar {
    width: 4px;
}
.S1col-8.new_sub::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .S1col-8.new_sub{
      margin-top: 16%;
      width: 100% !important;
      padding: 4px;
    }
}

.S1col-4.new_sub{
    margin-top: 80px;
    width: 20% !important;
    padding: 2px;
    box-sizing: border-box;
    height: 86vh;    
}
@media (max-width: 768px) {
    .S1col-4.new_sub{
      margin-top: 80px; 
      width: 100% !important;
    }
}


@media (max-width: 768px) {
    .S1frame27.sub_next{
        flex-direction: column;
        align-items: start;
        margin-bottom: 10px;
    }
}


@media only screen and (max-width: 768px) {
    .S3Frame1.subrow{
        flex-direction: row !important;
    }
    .PFcontent{
        max-width:100% !important;
    }
   
  }
@media (max-width: 768px) {
    .S3FooterContent {
        flex-direction: column;
        align-items: start;
        margin-bottom: 10px;
        gap: 10px;
    }
    .S3PlaceOrderButton{
        margin-top:12px;
    }
}



@media (max-width: 768px) {
    .S1frame25 span  {
        font-size:13px;
    }
  }

@media (max-width: 768px) {
    .S1frame25 div  {
        font-size:13px;
    }
  }

@media (max-width: 768px) {
    .S1frame25 {
        width:100%;
    }
    .S1frame25 span {
        width: 70%;
        overflow-wrap:anywhere;
    }
    .S1frame25 div {
        width:30%;
        overflow-wrap:anywhere;
    }
    .S1amount.add_sub{
        text-align: end;
    }
}