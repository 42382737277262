/* Add subscription.jsx */
.S1col-812{
    margin-top: 6%;
    width: 80% !important;
    max-height:87vh;
    padding: 20px;
    overflow-y: scroll;
    overflow-x:hidden ;
    background: #F9F9F9;  
}
.S1col-812::-webkit-scrollbar {
    width: 4px;
}
.S1col-812::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .S1col-812 {
      margin-top: 24%;
      width: 100% !important;
      padding: 4px;
    }
}
.S1col-412 {
    margin-top: 6%;
    width: 20% !important;
    padding: 2px;
    box-sizing: border-box;
    height: 86vh;    
}
@media (max-width: 768px) {
    .S1col-412 {
      margin-top: 24%; 
      width: 100% !important;
    }
  }
.AddSub-LPnav-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    /* height: 100%; */
}

.AddSub-LPnav-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    margin-left: 15px;
}

.AddSub-LPstep {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 44px;
}

.AddSub-LPcircle {
    /* Frame 1 */
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid #DADADA;
    border-radius: 20px;
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
}

    .AddSub-LPcircle.active {
        /* Frame 1 */
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        background: #FFF200;
        border-radius: 20px;
        display: flex; /* Use flexbox for centering */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
    }

    .AddSub-LPcircle span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #DADADA;
    }

        .AddSub-LPcircle span.active {
            color: #1A1A1A;
        }

.AddSub-LPstep-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.AddSub-LPtitle.active {
    color: #1A1A1A;
}

.AddSub-LPtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #9A9A9A;
}

.AddSub-LPsubtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #DADADA;
}

    .AddSub-LPsubtitle.active {
        color: #5B5B5B;
    }

.AddSub-LPvertical-dashed-line {
    display: flex;
    align-items: flex-start;
    width: 20px;
    height: 35px; /* Adjust the height as needed */
    border-left: 1px dashed #5B5B5B; /* Increase the thickness */
    margin-left: 15px;
    position: relative;
}

.AddSub-LPhelp-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    margin-left: 15px;
    margin-top: 50%;
}

/* .AddSub-LPhelp-section-addlicences {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    margin-left: 10px;
    margin-top: 70%;
} */

.AddSub-LPhelp-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

    .AddSub-LPhelp-details h2 {
        margin-left: 55px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5B5B5B;
    }

.AddSub-LPhelp-title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #5B5B5B;
}

.AddSub-LPhelp-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9A9A9A;
}

.AddSub-LPcontact-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    margin-top: 15px;
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #1A1A1A;
    background:#FAF9EB;
    border: 1px solid #DADADA;
}


/* Add subscription welcome container */
.AddSubClo-welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9EB;
    margin: 5%;
    padding: 15%;
    padding-top: 10%;
    border-radius: 10px;
    gap: 10px;
    box-sizing: border-box;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
}

.AddSubClo-welcomeHeader {
    text-align: center;
    margin-bottom: 1.5rem;
}

.AddSubClo-checkmarkIcon {
    background-size: cover;
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
}

.AddSubClo-welcomeTitle {
    /* background-color: #FFEA00; */
    font-weight: bold;
    color: #1A1A1A;
    padding: 5px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.AddSubClo-welcomeFooter {
    text-align: center;
}

.AddSubClo-buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-top: 1.5rem;
}

.AddSubClo-exploreButton {
    text-decoration: none;
    padding: 2% 3%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5%;
    color: #1A1A1A;
}

.AddSubClo-exploreButton div {
    font-weight: 500;
    font-size: 18px;
}

.AddSubClo-loginButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    border-radius: 5px;
    width: 100px;
    gap: 8px;
}

.AddSubClo-exploreButton {
    background-color: #dee2e6;
  
}

.AddSubClo-loginButton {
    background-color: #FFEA00;
    text-decoration: none;
    color: #000;
    padding: 10px;
}

.AddSubClo-loginButton span {
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}



/* AddSubForm.jsx */
.AddSubForm-S1subscription-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AddSubForm-LPServicesHeading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1%;
    gap: 16px;
    font-family: 'Inter', sans-serif;
    height: auto;
    margin-top: 20px;
}

.AddSubForm-LPServicesHeading span {
    /* background: #FFF200; */
    padding: 8px;
    font-size: 20px;
    font-weight: 650;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.AddSubForm-S1fields {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    align-items: flex-start;
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0 5% 1% 5%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    box-sizing: border-box;
    margin-left: 40px;
}

@media (max-width: 768px) {
    .AddSubForm-S1fields  {
        width: 100%;
        margin: 0px;
    }
  }


.AddSubForm-S1frame21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    height: auto;
    margin-top: 40px;
    box-sizing: border-box;
}

.AddSubForm-S1frame13 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.AddSubForm-S1frame13 label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #5B5B5B;
}

.AddSubForm-S1counter-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2%;
    justify-content: space-between;
    height: 100%;
}

.AddSubForm-S1count-display {
    font-size: 16px;
    font-family: Arial, sans-serif;
    margin-right: 10px;
}

.AddSubForm-S1button-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.AddSubForm-S1counter-button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 10px;
    padding: 0;
    width: 20px;
    height: 15px;
    text-align: center;
    margin: 1px 0;
    outline: none;
    user-select: none;
}

.AddSubForm-S1counter-button:hover {
    background-color: #e0e0e0;
}

.AddSubForm-S1existing-licenses {
    color: grey;
    margin-top: 20px;
}

.AddSubForm-S1existing-licenses-count {
    color: black;
}

.AddSubForm-S1noof-licenses {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    /*height: 40px; */
    background: #FAF9EB;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 5px;
    margin-top: 10px;
}

.AddSubForm-S1noof-licenses-count {
    display: flex;
    font-weight: bold;
}

.AddSubForm-S1noof-licenses-count span {
    margin-right: 10px;
}

.AddSubForm-S1noof-licenses-count img {
    margin-right: 10px;
}

.AddSubForm-S1frame23 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 5%;
    width: 100%;
}

.AddSubForm-S1frame23 span {
    width: 100%;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.AddSubForm-S1frame22 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    /* width: 100%; */
    padding: 0px;
    /* height: 80px; */
}

.AddSubForm-S1frame22 p {
    margin-right: 2px;
    color: #5B5B5B;
}
@media (max-width: 768px) {
    .AddSubForm-S1frame22 {
        width:100%;
    }
    .AddSubForm-S1frame22 p {
        width: 100%;
        overflow-wrap:anywhere;
    }
    .AddSubForm-S1frame22 div {
        width: 100%;
        overflow-wrap:anywhere;
    }
}
.AddSubForm-S1frame25,.AddSubForm-S1frame26,.AddSubForm-S1frame27{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.AddSubForm-S1frame25 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    /* margin-right: 80px; */
}

    .AddSubForm-S1frame25 span {
        display: flex;
        width:100%;
        align-items:flex-start;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1A1A1A;
        /* margin-left: 10px; */
    }

    @media (max-width: 768px) {
        .AddSubForm-S1frame25 span  {
            font-size:13px;
        }
      }

    .AddSubForm-S1frame25 div {
        font-weight: 700;
        font-size:16px;
        color: #1A1A1A;
    }

    @media (max-width: 768px) {
        .AddSubForm-S1frame25 div  {
            font-size:13px;
        }
      }

    @media (max-width: 768px) {
        .AddSubForm-S1frame25 {
            width:100%;
        }
        .AddSubForm-S1frame25 span {
            width: 70%;
            overflow-wrap:anywhere;
        }
        .AddSubForm-S1frame25 div {
            width:30%;
            overflow-wrap:anywhere;
        }
        .S1amount.add_sub{
            text-align: end;
        }
    }


    .AddSubForm-S1frame26 {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        /* justify-content: center; */
        align-items: center;
        padding: 14px 20px;
        gap: 8px;
        /* width: 59px;
        height: 20px; */
        background: #FFF200;
        border-radius: 8px;
        cursor: pointer;
        /* margin-right: 10px; */
    }
    
        .AddSubForm-S1frame26 span {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #1A1A1A;
        }
    
        .AddSubForm-S1frame26 div {
            width: 12px;
            height: 12px;
            margin-left: 3px;
        }
        .AddSubForm-S1frame27 {
            display: flex;
        
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            border: 1px solid #DADADA;
            border-radius: 8px;
            padding: 10px;
            /* gap:350px; */
        }
        @media (max-width: 768px) {
            .AddSubForm-S1frame27 {
                flex-direction: column;
                align-items: start;
                margin-bottom: 10px;
            }
        }
        @media (max-width: 768px) {
            .licenses-description {
               text-align:center;
            }
        }
        /* Add sub plac order jsx */

        .AddSubPlOd-S3main-content {
            display: flex;
            flex-direction: column;
            height: auto; 
            width: 100%;
        }
        .AddSubPlOd-S3frame-12 {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px;
            /* gap: 16px; */
            /* height: auto; */
        }
        .AddSubPlOd-LPServicesHeading {
            display: flex;
            justify-content: center ;
            flex-direction: column;
            align-items: center;
            padding-bottom:1%;
            gap: 16px;
            font-family: 'Inter', sans-serif;
            height: auto;
        }
        .AddSubPlOd-LPServicesHeading span {
            /* background:#FFF200; */
            padding: 8px;
            font-size: 20px;
            font-weight:650;
            border-radius: 8px;
            text-decoration: underline;
            text-decoration-color: #FFF200;
            text-underline-offset: 8px;
            text-decoration-thickness: 3px;
        }
        .AddSubPlOd-S3check-fields {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #1A1A1A;
        }
        .AddSubPlOd-S3Fields {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 20px;
            background: #FDFDFD;
            border-radius: 16px;
            overflow-y: auto;
            position: relative;
            width: 100%;
            max-height: calc(100vh - 80px);
        }
        
        .AddSubPlOd-S3Fields::-webkit-scrollbar {
            width: 6px;
        }
        
        .AddSubPlOd-S3Fields::-webkit-scrollbar-thumb {
            background-color: #FFF200;
            border-radius: 10px;
        }
        .AddSubPlOd-S3Frame {
            display: flex;
            width: 100%; 
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border: 1px solid #DADADA;
            justify-content: space-between;
            border-radius: 16px;
            box-sizing: border-box;
        }
        .AddSubPlOd-S3Frame1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            /* width: 100%; */
        }
        .AddSubPlOd-S3Framecolumn {
            display:flex;
            flex-direction:row;
            /* width: 100%; */
            justify-content:space-between;
        }
        .AddSubPlOd-S3Label {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            color: #5B5B5B;
            padding-right: 5px;
            flex: 0 0 auto;
        }
        .AddSubPlOd-S3Value {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            color: #1A1A1A;
            flex: 1;
            align-items:flex-end;
            justify-content:flex-end;
            gap:1%;
        }
        .AddSubPlOd-S3FrameMiddle {
            display: flex;
        }
        .AddSubPlOd-S3FrameAmount span {
            font-size: 14px;
            color: #1A1A1A;
            font-weight: 650;
        }
        .AddSubPlOd-S3Footer {
            position: sticky;
            bottom: 0;
            width: calc(100%);
            background: #FDFDFD;
            border: 1px solid #DADADA;
            padding: 16px;
            box-sizing: border-box;
            border-radius: 10px;
            z-index: 999;
        }
        
        .AddSubPlOd-S3FooterContent {
            display: flex;
            flex-direction:row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
        @media (max-width: 768px) {
            .AddSubPlOd-S3FooterContent {
                flex-direction: column;
                align-items: start;
                margin-bottom: 10px;
            }
        }
        
        .AddSubPlOd-S3PlaceOrderButton {
            padding: 14px 20px;
            background: #FFF200;
            border: none;
            border-radius: 8px;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-weight: 500;
            color: #1A1A1A;
            cursor: pointer;
        }
        @media (max-width: 768px) {
            .AddSubPlOd-S3PlaceOrderButton {
               margin-top:10px;
            }
        }
        .AddSubPlOd-S3PlaceOrderButton.disabled {
            background-color: #ccc;
            color: #666;
            cursor: not-allowed;
        }
        /* added */
.S3Frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #DADADA;
    justify-content: space-between;
    border-radius: 16px;
    box-sizing: border-box;
}
.S3Frame1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.S3Framecolumn {
    display:flex;
    flex-direction:row;
    /* align-items: flex-start; */
    width: 100%;
    justify-content:space-between;
}
.S3Label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #5B5B5B;
    word-wrap: break-word;
    flex: 0 0 auto;
}
.S3FrameAmount span{
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 650;
}

@media (max-width: 768px) {
    .S3FrameAmount span {
        overflow-wrap: anywhere;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.AddSubForm-S1count-display {
    border: none; /* Removes the border */
    background-color: transparent; /* Makes the background transparent */
    font-size: 16px; /* Adjust the font size as needed */
    outline: none; /* Removes the outline when the input is focused */
    width: 100px; /* Adjust width as needed */
}

/* spinner */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.spinner {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(255, 242, 0);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

  .sidebar {
    background-color: white !important;
    display: block;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      display: none;
      transition: all 0.3s ease;
      position: absolute;
      width: 100%;
      z-index: 1000;
      background-color: white;
      height: 100%;
    }
    
    .sidebar.open {
      display: block;
    }
  
    .hidden-content {
      display: none;
    }
  
    .toggle-sidebar-btn {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1100;
    }
  } 

.hamburger-icon {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25px;
    height: 18px;
    cursor: pointer;
    padding-right: 15px;
    margin-bottom:5px;
  }
  
  .bar {
    width: 26px;
    height: 4px;
    background-color: #2c2a2a;
    border-radius: 2px;
    transition: 0.3s;
  }
  
  .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  @media only screen and (max-width: 768px) {
    .hamburger-icon {
      display: flex;
      align-items: center;
    }
    .bar {
      display: block;
      align-items: center;
    }
    .maincontent .row{
        margin:0px;
    }
  }



  
.S3Fields {
    flex-grow: 1; /* Allow this section to grow and fill available space */
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background: #FDFDFD;
    border-radius: 16px;
    overflow-y: auto; 
    position: relative; /* Positioning context for the sticky footer */
    width: 100%;
    max-height: calc(100vh - 80px);
}

@media only screen and (max-width: 768px) {
    .S3Fields{
        padding: 9px;
        overflow-y: visible;
        max-height: none; 
    }
  }


.S3Fields::-webkit-scrollbar {
    width: 4px;
}

.S3Fields::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .S3check-fields {
        text-align: center;
        margin-top:8px;
    }
}
  
  
   