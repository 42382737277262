/* .mainContainer {
    display: flex;
    box-sizing: border-box;
    margin-left: 5%;
    width: 90%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 20px;
  }
  .formContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width:95%;
    gap:5%;
  }
  
  .header, .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title {
    color: #1A1A1A;
    background: #FFF200;
    font-weight: 700;
    padding:10px;
  }
  
  .subTitle, .questionBox p, label, .confirmationBox p {
    color: #5B5B5B;
    font-size: 14px;
    
  }
  
  .optionsContainer {
    display: flex;
    justify-content: space-between;
    gap:2%; 
    width: 100%;
  }
  
  .optionBox {
    display: flex;
    background: #FAF9EB;
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    padding: 24px 40px;
    align-items: center;
    gap: 20px;
  }
  
  .optionIcon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
  
  .yellowBg {
    background: #FFF200;
  }
  
  .blueBg {
    background: radial-gradient(circle, #AE7FE2 6.4%, #0078D4 100%);
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  
  input {
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
  }
  
  .confirmationBox {
    background: #8BF2A8;
    border-radius: 8px;
    padding: 16px 20px;
    width: 100%;
    text-align: center;
  }
   */

   .maindiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: 'Inter', sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: center ;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-bottom:1%;
    gap: 16px;
    font-family: 'Inter', sans-serif;
    /* width: 482px; */
    height: auto;
  }
  .header span{
    /* background:#FFF200; */
    padding: 8px;
    font-size: 28px;
    font-weight:650;
    border-radius: 8px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}
  
  .title {
    font-size: 28px;
    font-weight: 650;
    color: #1A1A1A;
    background: #FFF200;
    padding:10px;
  }

  .subTitle {
    font-size: 16px;
    color: #666;
  }
  
  .mainContainer {
    display: flex;
    box-sizing: border-box;
    /* margin-left: 5%; */
    width: 90%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 20px;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .questionBox {
    margin-bottom: 20px;
    color: #5B5B5B;
    font-size: 14px;
    
  }
  .questionBox p{
    margin-left: 5%;
  }
  
  .optionsContainer {
    display: flex;
    justify-content: center;
    gap:2%; 
    width: 70%;
    margin-left: 15%;
  }
  
  .optionBox {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6%;
    padding-top: 3%;
    padding-bottom: 0%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    gap:3%;
 
  }
  
  .optionBox.selected {
    background-color: #FAF9EB;
  }
  
  .optionIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .yellowBg {
    background-color: yellow;
  }
  
  .blueBg {
    background-color: blue;
  }
  
  .formContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:5%;
    margin: 4%;
    margin-top: 0%;
    margin-bottom:0%;
    width: 90%;
  }
  
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    width:100%;
    gap:5px;
    margin-bottom: 15px;

  }
  
  .inputGroup label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #5B5B5B;
  }
  
  .inputGroup input {
    width: 100%;
    padding:6px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .confirmationBox {
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 8px;
    width:60%;
    height: 50%;
    margin-left:15%;
    font-family: 'Inter', sans-serif;
    background: #8BF2A8;
    border-radius: 8px;
    color: #1A1A1A;

  }
  .additoinalinfoHeading{
    margin-left:4%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 550; 
    font-size: 16px;

  }
  .additionalInfo {
    padding: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1A1A1A;
    align-self: stretch;
   

}

  
  .selected {
    background-color: #FAF9EB;
  }
  
  .optionIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .yellowBg {
    background-color: yellow;
  }
  
  .blueBg {
    background-color: blue;
  }
  
 
  .inputGroup input{
    border:1px solid gray;
    border-radius: 5px;
  }
  
  .domainInputWrapper {
    display: flex;
    align-items: center;
    gap:5px;
    border:1px solid gray;
    border-radius: 5px;
    margin-top:5px;
  }
  .domainInputWrapper img{
    width:10%;
  }
  
  .rightmark img{
    width:70%;
  }
  
  /* input[type="text"] {
    flex: 1;
    padding-right: 10%px; /* Adjust based on your need */
   
  
  .fixedPlaceholder {
    white-space: nowrap;
    /* margin-left: -15px;  */
    color:#1A1A1A;
    background-color: #E0E0E0;
    padding:5px;
    border-radius:4px;
  }
  
  .errorText {
    color:#E85E5E;
    font-size: 11px;
    width: 100%;
  }
  
  .MS-frame26cont{
    display:flex;
    width:100%;

}
.subscription-instructions{
  font-family: 'Inter', sans-serif;
  margin-left:4%;
  padding-bottom: 3%;
  align-items: flex-start;
  gap:5px;
  line-height: 1.4;
}
.subscription-instructions h2{
  font-weight: 550;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color:#1A1A1A;
}
.subscription-instructions p,ul{
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #5B5B5B;
  gap:5px;
  justify-content: flex-start;
  align-items: flex-start;

}
.MS-frame26{
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  background: #FFF200;
  border-radius: 8px;
  margin-right:20px;
  margin-bottom: 15px;
  cursor: pointer;
}
.input-spinner{
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #FFF200;
  border-radius: 50%;
  width: 39px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.button-spinner{
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message-box {
  padding: 10px 15px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  margin-left:4%;
  margin-bottom: 2%;
  display: flex;
  align-items: flex-start;
  max-width: 800px;
}

.message-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-icon img {
  width: 20px;
  height: 20px;
}

/* Error message styling */
.message-box.error {
  border: 1px solid #e41c13;
  background-color: #f8d7da;
  color: #721c24;
}

/* Info message styling */
.message-box.info {
  border: 1px solid #b3b3b3;
  background-color: #f1f1f1;
  color: #555555;
}
.message-box.success{
  border: 1px solid whitesmoke;
  background-color: #afffa6;
  color:#000000;
}

/* Icon color change using CSS filters */
.icon-error {
  filter: invert(21%) sepia(85%) saturate(3574%) hue-rotate(342deg) brightness(90%) contrast(97%);
}

.icon-info {
  filter: invert(60%) sepia(6%) saturate(5%) hue-rotate(11deg) brightness(93%) contrast(84%);
}

.message-box p {
  margin: 0;
  font-size: 11px;
  line-height: 1.5;
}
.link-text{
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .optionsContainer{
    flex-direction: column;
    margin-left: 1%;
    margin: 1%;
    width: 100%;
    gap: 10px;
  }
  .formContainer{
    flex-direction: column;
  }
  .link-text{
    font-size: 10px;
  }
  .MS-frame26cont{
    flex-direction: column;
  }
  .MS-frame29cont{
    display: flex;
    flex-direction: column;
  }
}