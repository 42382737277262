/* renew sub.jsx */

.S1col-81{
    max-height:100vh;
    padding: 20px;
    overflow-y: scroll;
    overflow-x:hidden ;
    background: #F9F9F9;
    width: 80% !important;
}
.S1col-81::-webkit-scrollbar {
    width: 4px;
}
.S1col-81::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}
.S1col-41 {
    margin-top: 6%;
    width: 20% !important;
    padding: 2px;
    box-sizing: border-box;
    height: 87vh;
   

    
}
.RenewSub-LPnav-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    /* height: auto; */
}

.RenewSub-LPnav-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    margin-left: 15px;
}

.RenewSub-LPstep {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 32px;
}

.RenewSub-LPcircle {
    /* Frame 1 */
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid #DADADA;
    border-radius: 20px;
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
}

.RenewSub-LPcircle.active {
    /* Frame 1 */
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    background: #FFF200;
    border-radius: 20px;
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.RenewSub-LPcircle span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #DADADA;
    /* margin-left: 10px; */
}

.RenewSub-LPcircle span.active {
    color: #1A1A1A;
}

.RenewSub-LPstep-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.RenewSub-LPtitle.active {
    color: #1A1A1A;
}

.RenewSub-LPtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #9A9A9A;
}

.RenewSub-LPsubtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #DADADA;
}

.RenewSub-LPsubtitle.active {
    color: #5B5B5B;
}

.RenewSub-LPvertical-dashed-line {
    display: flex;
    align-items: flex-start;
    width: 20px;
    height: 35px; /* Adjust the height as needed */
    border-left: 1px dashed #5B5B5B; /* Increase the thickness */
    /* margin-right: 224px; */
    margin-left:15px;
    position: relative;
}

.RenewSub-LPhelp-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    /* position: relative; */
    /* left: calc(50% - 272px/2); */
    /* width: 262px;
    height: 153px; */
    margin-left: 15px;
    margin-top:65%;
}

.RenewSub-LPhelp-section-addlicences {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 12px;
    /* position: relative;
    left: calc(50% - 272px/2); */
    /* width: 262px;
    height: 153px; */
    margin-left: 10px;
    margin-top: 70%;
}

.RenewSub-LPhelp-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /*text-align: center;*/
    /* width: 272px;
    height: 61px; */
}

.RenewSub-LPhelp-details h2 {
    margin-left: 55px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5B5B5B;
    /* Inside auto layout */
}

.RenewSub-LPhelp-title {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #5B5B5B;
}

.RenewSub-LPhelp-description {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #9A9A9A;
}

.RenewSub-LPcontact-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    margin-top: 15px;
    /* width: 117px;
    height: 44px; */
    border: 1px solid #9A9A9A;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #1A1A1A;
    background:#FAF9EB;
    border: 1px solid #DADADA;
}
/* Renew Sub closure.jsx */
.RenewSubCl-welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9EB;
    margin: 5%;
    padding: 15%;
    padding-top: 10%;
    border-radius: 10px;
    gap: 10px;
    box-sizing: border-box;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
}

.RenewSubCl-welcomeHeader {
    text-align: center;
    margin-bottom: 1.5rem;
}

.RenewSubCl-checkmarkIcon {
    background-size: cover;
    width: 50px;
    height: 50px;
    margin: 0 auto 1rem;
}

.RenewSubCl-welcomeTitle {
    /* background-color: #FFEA00; */
    font-weight: bold;
    color: #1A1A1A;
    padding: 5px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.RenewSubCl-credentialsContainer {
    border: 1px solid #9A9A9A;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 40px;
    background: #FAF9EB;
}

.RenewSubCl-credentialsTitle {
    font-weight: 500;
    margin-bottom: 1rem;
    color: #1A1A1A;
}

.RenewSubCl-credentialItem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.RenewSubCl-credentialLabel {
    color: #5B5B5B;
}

.RenewSubCl-credentialValue {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.RenewSubCl-copyIcon {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 1rem;
}

.RenewSubCl-copyMessage {
    color: green;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.RenewSubCl-welcomeFooter {
    text-align: center;
}

.RenewSubCl-buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-top: 1.5rem;
}

.RenewSubCl-exploreButton {
    text-decoration: none;
    padding: 2% 3%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5%;
    color: #1A1A1A;
    background-color: #dee2e6;
    
}

.RenewSubCl-exploreButton div {
    font-weight: 500;
    font-size: 18px;
}

.RenewSubCl-loginButton {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    border-radius: 5px;
    gap: 8px;
    background-color: #FFEA00;
    color: #000;
    padding: 10px;
}

.RenewSubCl-loginButton span {
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}

/* Renew subForm.jsx */
.RenewSubForm-S1subscription-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RenewSubForm-S1form-heading {
    /* Frame 12 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    /*padding: 40px;*/
    gap: 16px;

    width: auto;
    height: 70px;
}

.RenewSubForm-S1form-heading p {
    margin-top: -10px;
}

.RenewSubForm-S1title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    height: 35px;
    /* background: #FFF200; */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 39px;
    /* identical to box height */
    text-align: center;
    color: #1A1A1A;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 5px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}

.RenewSubForm-renew-S1fields {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    align-items: flex-start;
    width: 90%;
    height: auto;
    padding: 0 5% 1% 5%;
    background: #FDFDFD;
    border: 1px solid #DADADA;
    border-radius: 16px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 50px;
}

.RenewSubForm-S1frame21 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* width: 400px; */
    height: auto;
    margin-top: 40px;
    box-sizing: border-box;
}

.RenewSubForm-S1frame13,
.RenewSubForm-S1frame14,
.RenewSubForm-S1frame15,
.RenewSubForm-S1frame19,
.RenewSubForm-S1frame17,
.RenewSubForm-S1frame18,
.RenewSubForm-S1frame23,
.RenewSubForm-S1frame22,
.RenewSubForm-S1frame27,
.RenewSubForm-S1frame25,
.RenewSubForm-S1frame26 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.RenewSubForm-S1frame13,
.RenewSubForm-S1frame14,
.RenewSubForm-S1frame15 {
    width: 100%;
}

.RenewSubForm-S1frame13 label,
.RenewSubForm-S1frame14 label,
.RenewSubForm-S1frame15 label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #5B5B5B;
}

.RenewSubForm-S1counter-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2%;
    justify-content: space-between;
    /* Frame 12 */
    width: 50%;
    height: 100%;
}

.RenewSubForm-S1count-display {
    font-size: 16px;
    font-family: Arial, sans-serif;
    margin-right: 10px;
}

.RenewSubForm-S1button-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.RenewSubForm-S1counter-button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    font-size: 10px;
    padding: 0;
    width: 20px;
    height: 15px;
    text-align: center;
    margin: 1px 0;
    outline: none;
    user-select: none;
}

.RenewSubForm-S1counter-button:hover {
    background-color: #e0e0e0;
}

.RenewSubForm-S1frame19 {
    width: 100%;
}

.RenewSubForm-S1frame17,
.RenewSubForm-S1frame18 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.RenewSubForm-S1frame18 label,
.RenewSubForm-S1frame17 label {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    height: 24px;
}

.RenewSubForm-S1frame18 input,
.RenewSubForm-S1frame17 input {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.RenewSubForm-S1frame12 {
    display: flex;
    align-items: center;
    /* width: 360px;
    height: 48px; */
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
}

.RenewSubForm-S1button-container1 {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
}

.RenewSubForm-S1frame23 {
    /* width: 360px; */
    margin-top: 5%;
}

.RenewSubForm-S1frame23 span {
    /* Your Order Summary: */
    width: 360px;
    height: 17px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #5B5B5B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.RenewSubForm-S1frame22 {
    width: 100%;
    /* gap: 12px;*/
    padding: 0px;
    height: 80px;
}

.RenewSubForm-S1frame22 span {
    margin-right: 50px;
}

.RenewSubForm-S1frame27 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 10px;
    /* gap: 350px; */
}

.RenewSubForm-S1frame25 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    /* margin-right: 80px; */
}

.RenewSubForm-S1frame25 span {
    display: flex;
    width: 100%;
    align-items: flex-start;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
    /* margin-left: 10px; */
}

.RenewSubForm-S1frame25 div {
    font-weight: 700;
    color: #1A1A1A;
}

.RenewSubForm-S1frame26 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    /* justify-content: center; */
    align-items: center;
    padding: 14px 20px;
    gap: 8px;
    /* width: 59px;
    height: 20px; */
    background: #FFF200;
    border-radius: 8px;
    cursor: pointer;
    /* margin-right: 10px; */
}

.RenewSubForm-S1frame26 span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
}

.RenewSubForm-S1frame26 div {
    width: 12px;
    height: 12px;
    margin-left: 3px;
}
/* Renew Sub Place Order */

.RenewSubPlOd-S3main-content {
    display: flex;
    flex-direction: column;
    height: auto; /* Ensure main content takes up the full viewport height */
    width: 100%;
}
.RenewSubPlOd-S3frame-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    /* gap: 16px; */
    height: auto;
}
.RenewSubPlOd-LPServicesHeading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* padding-top: 4%; */
    padding-bottom: 1%;
    gap: 16px;
    font-family: 'Inter', sans-serif;
    /* width: 482px; */
    height: auto;
}
.RenewSubPlOd-LPServicesHeading span {
    background: #FFF200;
    padding: 8px;
    font-size: 28px;
    font-weight: 650;
    border-radius: 8px;
}
.RenewSubPlOd-S3check-fields {
    /* width: 410px;
    height: 19px; */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
}
.RenewSubPlOd-S3Fields {
    flex-grow: 1; /* Allow this section to grow and fill available space */
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background: #FDFDFD;
    border-radius: 16px;
    overflow-y: auto; 
    position: relative; /* Positioning context for the sticky footer */
    width: 100%;
    max-height: calc(100vh - 80px); /* Adjust height to leave space for footer */
}

.RenewSubPlOd-S3Fields::-webkit-scrollbar {
    width: 6px;
}

.RenewSubPlOd-S3Fields::-webkit-scrollbar-thumb {
    background-color: #FFF200;
    border-radius: 10px;
}
.RenewSubPlOd-S3Frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #DADADA;
    justify-content: space-between;
    border-radius: 16px;
    box-sizing: border-box;
}
.RenewSubPlOd-S3Frame1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.RenewSubPlOd-S3Framecolumn {
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    width: 100%;
    justify-content: space-between;
}
.RenewSubPlOd-S3Label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #5B5B5B;
    flex: 0 0 auto;
}
.RenewSubPlOd-S3Value {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #1A1A1A;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1%;
}
.RenewSubPlOd-S3FrameMiddle {
    display: flex;
}
.RenewSubPlOd-S3FrameAmount span {
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 650;
}
.RenewSubPlOd-S3Footer {
    position: sticky;
    bottom: 0;
    width: calc(100%); /* Adjust width to fit within padding if needed */
    background: #FDFDFD;
    border: 1px solid #DADADA;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 999;
}

.RenewSubPlOd-S3FooterContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.RenewSubPlOd-S3PlaceOrderButton {
    padding: 14px 20px;
    background: #FFF200;
    border: none;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #1A1A1A;
    cursor: pointer;
}

.RenewSubPlOd-S3frame-27 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 50px; */
    /* margin-bottom: 50px; */
    margin-top: 25px;
}
.RenewSubPlOd-S3frame-27 span{
    /* background-color: #FFF200; */
    margin-top:10px;
    font-size: 20px;
    padding:8px;
    font-weight: 650;
    border-radius: 5px;
    text-decoration: underline;
    text-decoration-color: #FFF200;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
}
.RenewSub-S3check-fields {
    /* width: 410px;
    height: 19px; */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1A1A1A;
    margin-top: 15px;
}

/* added */
.S3Frame {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #DADADA;
    justify-content: space-between;
    border-radius: 16px;
    box-sizing: border-box;
}
.S3Frame1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.S3Framecolumn {
    display:flex;
    flex-direction:row;
    /* align-items: flex-start; */
    width: 100%;
    justify-content:space-between;
}
.S3Label {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #5B5B5B;
    word-wrap: break-word;
    flex: 0 0 auto;
}
.S3FrameAmount span{
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 650;
}

.count-input-box {
    width: 100%;
    height: 40px;
    background: #FDFDFD;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: 2px;
    padding: 8px;
}

/* spinner */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.spinner {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(255, 242, 0);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

  @media (max-width: 768px) {
    .S3Frame1.rowFlex {
        flex-direction: row !important;
    }
    
    .S3Frame1.columnFlex {
        flex-direction: column !important;
    }
    
    .S3Framecolumn {
        flex-direction: row;
        align-items: flex-start; 
        margin-bottom: 16px;
    }

    .S3FrameAmount span {
        font-size: 16px; 
    }

    .S3Label{
        max-width: 50%; 
    }
    .S3Value {
        max-width: 50%; 
    }

    .AddSubPlOd-S3Frame1 {
        display: flex;
        flex-direction: row;
    }

    .AddSubPlOd-S3Framecolumn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 16px;
    }

    .AddSubPlOd-S3Label {
        max-width: 50%; 
        flex-basis: 50%; 
    }

    .AddSubPlOd-S3Value {
        max-width: 50%;
        flex-basis: 50%;
        overflow-wrap:anywhere;
    
    }
    .AddSubPlOd-S3Value p {
        margin: 0;
    }
}
