.pagination-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width:100%;
}

.pagination {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    display: flex;
    gap: 5px;
    margin: 0 10px;
    
}

.page-item {
    display: inline;
}

.page-link1 {
    padding: 5px;
    border: none;
    background: none;
    cursor: pointer;
    color: #999;
}

.page-item.active .page-link {
    color: #000;
}

.pagination-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    color: #000;
}

    .pagination-button:disabled {
        color: #ccc;
    }
